<div id="book-preview-container" class="book-preview-container">

  <h1 class="preview-header">Einblick ins Buch</h1>

  <ngx-extended-pdf-viewer [src]="BookSrc" [contextMenuAllowed]="false" [backgroundColor]="'transparent'" [delayFirstView]="10"
    [showBorders]="true" [pageViewMode]="'single'" [useBrowserLocale]="true" [showToolbar]="false"  [height]="PdfViewHeight"
    class="pdf-viewer" [(page)]="CurrentPage" [showPagingButtons]="false" [sidebarVisible]="false" tabindex="-1"
    [showScrollingButton]="false" [zoom]="ZoomMode" (pdfLoaded)="PdfLoadedCommand.Execute()"></ngx-extended-pdf-viewer>

  <div class="lower-container">

    <p class="link" (click)="SwitchPdfCommand.Execute()">Eine Rose zum Weitergeben</p>
    <button (click)="OrderBookCommand.Execute();" mat-raised-button color="primary" class="order-button">Hier
      Bestellen</button>
  </div>

  <div id="scroll-bar-hack"></div>

  <svg (click)="PrevPageCommand.Execute();" class="left-arrow" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 121.4 231.18">
    <defs>
      <style>
        .left-arrow-style {
          fill: #9c7d4a;
        }

      </style>
    </defs>
    <path class="left-arrow-style"
      d="M81.31,182.73,28.54,130.08c-7.06-7-7.07-22.57,0-29.64L81.19,47.58,120.86,7.76c-4.68-7.95-12.15-10.59-18.6-4.12L57.94,48.12,5.28,101c-7,7.07-7,22.6,0,29.64l52.76,52.65,44.4,44.3c6.63,6.61,14.31,3.63,18.94-4.82Z" />
  </svg>

  <svg (click)="NextPageCommand.Execute();" class="right-arrow" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 121.4 231.18">
    <defs>
      <style>
        .right-arrow-style {
          fill: #9c7d4a;
        }

      </style>
    </defs>
    <path class="left-arrow-style"
      d="M81.31,182.73,28.54,130.08c-7.06-7-7.07-22.57,0-29.64L81.19,47.58,120.86,7.76c-4.68-7.95-12.15-10.59-18.6-4.12L57.94,48.12,5.28,101c-7,7.07-7,22.6,0,29.64l52.76,52.65,44.4,44.3c6.63,6.61,14.31,3.63,18.94-4.82Z" />
  </svg>
</div>

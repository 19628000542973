

<div class="menu-container">
    <div class="hamburger-container">
        <div id="burger-button" class="hamburger-slim" (click)="ToggleMenu();">
        </div>
    </div>
    <ul id="menu" class="menu">
        <a class="menuEntry" *ngFor="let item of MenuItems" (click)="item.Click.Execute(); HideMenu(); ">{{item.Text}}</a>
        <!-- <img class="img-language" src="/assets/Startseite/DQDL_SS_6_21.png" alt="Languange"> -->
    </ul>

    <div id="logo" class="logo-container">
        <img class="logo" src="/assets/Startseite/DQDL_SS_32_21.png" alt="Webfacture Logo">
    </div>
</div>
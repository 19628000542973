import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, merge, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { AudioPlaylist } from './AudioPlaylist';
import { Song } from './Song';
import { map, publishReplay, startWith } from 'rxjs/operators';
import { None } from '@webfacture/simplicitas-shared';

@Injectable({
  providedIn: 'root'
})
export class AudioManagerService implements OnDestroy
{
  private mPlaylist: AudioPlaylist;
  private mSongEndedSupscription: Subscription;
  private mMusicPlayer: HTMLAudioElement;
  private mCurrentSong: Song;
  private mCurrentSongSubject: ReplaySubject<Song>;
  private mSongsLoadedSubject: Subject<None>;

  public CurrentSong: Observable<Song>;
  public IsPlaying: Observable<boolean>;
  public MusicProgress: Observable<number>;
  public SongDuration: Observable<number>;

  public SongsLoaded: Observable<None>;

  constructor()
  {
    this.mMusicPlayer = new Audio();
    this.mPlaylist = AudioPlaylist.Empty();
    this.mCurrentSongSubject = new ReplaySubject<Song>(1);
    this.CurrentSong = this.mCurrentSongSubject;
    this.mSongsLoadedSubject = new Subject<None>();
    this.SongsLoaded = this.mSongsLoadedSubject;

    this.mSongEndedSupscription = fromEvent(this.mMusicPlayer, 'ended')
        .subscribe((_) => this.NextSong(true));

    this.IsPlaying = merge(
      fromEvent(this.mMusicPlayer, 'play')
        .pipe(map(_ => true)),
      fromEvent(this.mMusicPlayer, 'pause')
        .pipe(map(_ => false)))
    .pipe(startWith(false));

    this.SongDuration = fromEvent(this.mMusicPlayer, 'loadedmetadata')
      .pipe(map(_ => this.mMusicPlayer.duration));

    this.MusicProgress = fromEvent<TrackEvent>(this.mMusicPlayer, 'timeupdate')
      .pipe(map(x => (x.target as HTMLAudioElement).currentTime));
  }

  ngOnDestroy(): void
  {
    this.mSongEndedSupscription.unsubscribe();
    this.mMusicPlayer = null;
  }

  public async ConfigurePlaylist(playlist: AudioPlaylist) : Promise<void>
  {
    this.mPlaylist = playlist;

    if(!this.mPlaylist.Empty)
    {
      this.mSongsLoadedSubject.next(new None());
      this.mCurrentSong = this.mPlaylist.Songs[0];
      this._UpdateMediaPlayer();
      this.mCurrentSongSubject.next(this.mCurrentSong);
    }
  }

  public async StartPlay() : Promise<void>
  {
    const sanitizedSource = encodeURI(this.mCurrentSong.Source);
    if(!this.mMusicPlayer.src.includes(sanitizedSource))
    {
      await this.mCurrentSong?.LoadSong(this.mMusicPlayer);
    }

    await this.mMusicPlayer.play();

    console.debug('AudioMangager: ' + this.mCurrentSong.Source + ' is playing');
  }

  public async StopPlay() : Promise<void>
  {
    this.mMusicPlayer.pause();
  }

  public async SetTime(currentTime: number) : Promise<void>
  {
    const sanitizedSource = encodeURI(this.mCurrentSong.Source);
    if(!this.mMusicPlayer.src.includes(sanitizedSource))
    {
      await this.mCurrentSong?.LoadSong(this.mMusicPlayer);
    }

    this.mMusicPlayer.currentTime = currentTime;
  }

  public async NextSong(autoplay: boolean = false) : Promise<void>
  {
    if(!this.mPlaylist.Empty)
    {
      const autoStart = !this.mMusicPlayer.paused;
      await this._ResetMediaPlayer();
      this.mCurrentSong = this.mPlaylist.GetNextSong(this.mCurrentSong);
      this._UpdateMediaPlayer();
      this.mCurrentSongSubject.next(this.mCurrentSong);
      if(autoplay || autoStart)
        await this.StartPlay();
    }
  }

  private _UpdateMediaPlayer()
  {
    this.mMusicPlayer.src = this.mCurrentSong.Source;
    this.mMusicPlayer.load();
  }

  public async RestartSong() : Promise<void>
  {
    this.mMusicPlayer.currentTime = 0;
  }

  public async PrevSong() : Promise<void>
  {
    if(!this.mPlaylist.Empty)
    {
      await this._ResetMediaPlayer();
      this.mCurrentSong = this.mPlaylist.GetPrevSong(this.mCurrentSong);
      this._UpdateMediaPlayer();
      this.mCurrentSongSubject.next(this.mCurrentSong);
      await this.StartPlay();
    }
  }

  private async _ResetMediaPlayer() : Promise<void>
  {
    this.mMusicPlayer.currentTime = 0;
    this.mMusicPlayer.pause();
  }

  public IsInStaringPart() : boolean
  {
      return this.mMusicPlayer.currentTime < 5;
  }
}

import { ViewDescription, ViewRoute } from "@webfacture/simplicitas-shell";
import { LoadingViewComponent } from './loading-view.component';


@ViewDescription
export class LoadingViewViewDescription
{
    public static ViewRoute: ViewRoute = new ViewRoute("Loading", LoadingViewComponent);

    ViewRoute: ViewRoute = LoadingViewViewDescription.ViewRoute;
}
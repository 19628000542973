<div id="the-artist-section" class="the-artist-section">
  <div class="parallax safari_only">

  </div>

  <div class="outer-about-container">
    <div class="bow-1-container">
      <svg class="bow-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 157.12 6.88">
        <defs>
          <style>
            .styling-bow-1 {
              fill: #9c7d4a;
            }

          </style>
        </defs>
        <path class="styling-bow-1"
          d="M0,7.06c-.12-.21,2.78-2.74,4.51-4,6-4.22,13.12-3.47,38.47.73a92.22,92.22,0,0,0,13,1.61c9.49.36,16-1.2,16-.89S65.27,6.9,56.73,7.14a80.51,80.51,0,0,1-11.86-.8c-10.75-1.2-14.13-2.29-23.56-3A65.31,65.31,0,0,0,8.8,3.91,19.85,19.85,0,0,0,4.37,5C1.75,5.93.1,7.22,0,7.06Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow-1"
          d="M157.12,7.06c.12-.21-2.78-2.74-4.51-4-6-4.22-13.12-3.47-38.47.73A92.1,92.1,0,0,1,101.2,5.44c-9.49.36-16-1.2-16-.89s6.65,2.35,15.2,2.59a80.31,80.31,0,0,0,11.85-.8c10.75-1.2,14.13-2.29,23.56-3a65.31,65.31,0,0,1,12.51.57,19.94,19.94,0,0,1,4.44,1C155.37,5.93,157,7.22,157.12,7.06Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow-1"
          d="M55.31,2.84c-.08.37,1.67,1.33,3.44,1.76a13.42,13.42,0,0,0,3.92.24,24.12,24.12,0,0,0,3.66-.36A34.86,34.86,0,0,0,71.62,3S66.68,3.9,66,3c-.57-.67-.22-1.35-.77-1.76-.85-.63-2.09.71-4.09,1.4C58,3.73,55.41,2.39,55.31,2.84Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow-1"
          d="M101.76,2.84c.09.37-1.67,1.33-3.43,1.76a13.51,13.51,0,0,1-3.93.24,24.23,24.23,0,0,1-3.66-.36A34.86,34.86,0,0,1,85.45,3h0s4.94.88,5.68,0c.56-.67.21-1.35.76-1.76C92.74.63,94,2,96,2.66,99.11,3.73,101.66,2.39,101.76,2.84Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow-1"
          d="M77.31,1.5c-1-1.08-2.76-.62-3-.54-.92.26-1.5.84-2.84.92a3.13,3.13,0,0,1-.68,0c-1.13-.16-1.4-.9-2.17-1a1.78,1.78,0,0,0-1.92,1.23A2,2,0,0,0,68.55,4c.49,0,.68-.41,1.72-.68.63-.16.79-.09.87,0,.4.33.09,1.25.43,1.44s.35-.15.76-.13a1.27,1.27,0,0,1,.72.32c.62.47,3.23,1.51,4.31.44A3.2,3.2,0,0,0,77.31,1.5Zm-7.8,1c-.13.26-.52.25-.75.18s-.57-.31-.53-.47.33-.19.49-.21.7-.09.82.21A.37.37,0,0,1,69.51,2.49Zm3.88.76a.6.6,0,0,1-.47.35.77.77,0,0,1-.64-.27C72,3,71.82,2.44,72,2.25s.45,0,.78.09a.89.89,0,0,1,.52.35A.65.65,0,0,1,73.39,3.25Zm1.87,1.62a.92.92,0,0,1-.72-.12c-.36-.21-.69-.68-.59-.89s.63-.13.94,0,.81.51.75.76S75.37,4.84,75.26,4.87Zm1.33-1.44c-.13.23-.44.23-.62.23a1.58,1.58,0,0,1-1.13-.55c-.27-.31-.53-.85-.38-1s.13-.09.67,0c.79.2,1.19.3,1.37.62A.79.79,0,0,1,76.59,3.43Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow-1"
          d="M79.82,1.5c1-1.08,2.76-.62,3-.54a10,10,0,0,0,2.84.92,3.12,3.12,0,0,0,.68,0c1.13-.16,1.4-.9,2.17-1a1.78,1.78,0,0,1,1.92,1.23A2,2,0,0,1,88.57,4c-.49,0-.67-.41-1.72-.68-.63-.16-.79-.09-.87,0-.4.33-.08,1.25-.42,1.44s-.35-.15-.77-.13a1.31,1.31,0,0,0-.72.32c-.62.47-3.22,1.51-4.31.44A3.2,3.2,0,0,1,79.82,1.5Zm7.8,1c.13.26.52.25.75.18s.56-.31.53-.47S88.56,2,88.41,2s-.71-.09-.82.21A.37.37,0,0,0,87.62,2.49Zm-3.89.76a.61.61,0,0,0,.48.35.78.78,0,0,0,.64-.27c.3-.32.45-.89.27-1.08s-.45,0-.78.09a.89.89,0,0,0-.52.35A.73.73,0,0,0,83.73,3.25ZM81.86,4.87a.91.91,0,0,0,.72-.12c.36-.21.7-.68.59-.89s-.63-.13-.94,0-.81.51-.75.76S81.75,4.84,81.86,4.87ZM80.54,3.43c.12.23.43.23.61.23a1.55,1.55,0,0,0,1.13-.55c.27-.31.53-.85.39-1s-.13-.09-.67,0c-.79.2-1.19.3-1.38.62A.79.79,0,0,0,80.54,3.43Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow-1"
          d="M77.35,4.66A1.32,1.32,0,0,1,78,3.45v0a1.14,1.14,0,0,1-.54-1,1.1,1.1,0,0,1,1-1.2,1,1,0,0,1,1,1.11,1.18,1.18,0,0,1-.56,1v0a1.26,1.26,0,0,1,.69,1.17,1.2,1.2,0,0,1-1.16,1.31A1.14,1.14,0,0,1,77.35,4.66Zm1.83,0a1,1,0,0,0-.75-1,.92.92,0,0,0-.61.92.75.75,0,0,0,.68.86A.72.72,0,0,0,79.18,4.63ZM77.91,2.38a.84.84,0,0,0,.65.83.85.85,0,0,0,.52-.82c0-.36-.16-.73-.57-.73S77.91,2,77.91,2.38Z"
          transform="translate(0 -0.28)" />
      </svg>
    </div>

    <div id="about-section" class="about-section">

      <div class="left-pic">

        <img src="/assets/Startseite/min/DQDL_SS_29_21.jpg" alt="Der Künstler beim Malen">

      </div>

      <div class="right-text">

        <h1>ÜBER DEN KÜNSTLER</h1>

        <p>
          Maximilian Jantscher <br>
          Jahrgang 1966, glücklich verheiratet, 4 Kinder, Dipl. Lebens-
          und Sozialberater, Kunst- und Lehrtherapeut sowie Künstler (בצלאל).
          <br> <br>
          Mit meiner Malerei versuche ich Gottes Schöpfung in allen
          ihren Facetten, Farben und Eindrücken mit den mir zur
          Verfügung stehenden Malmitteln in einem Bild festzuhalten. Die
          Gemälde erzählen Geschichten, die das Auge versteht und sie
          dem Herzen und dem Verstand erklärt.
          <br> <br>
          Sie sind ein stimmungsvolles Plädoyer dafür, dass der Mensch
          kein Zufallsprodukt ist, sondern die geniale Handschrift eines
          liebevollen Schöpfers trägt.
          <br> <br>
          Es ist Zeit für einen neuen Blick auf den Ursprung des Lebens:
          Einen, der Sinn macht, statt ihn zu rauben. Einen, den Gott
          nicht nimmt, sondern ihn für ein befreites Leben wiederbringt.
          Kunst über den Schöpfer. Über sein Wort. Über sein Werk. Über
          seine Liebe zu uns Menschen. Das ist meine Malerei, das ist
          Kunst zu glauben!

        </p>

      </div>

    </div>

    <div class="bow-2-container">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 157.12 6.88" class="bow-2">
        <defs>
          <style>
            .styling-bow {
              fill: #9c7d4a;
            }

          </style>
        </defs>
        <path class="styling-bow"
          d="M0,7.06c-.12-.21,2.78-2.74,4.51-4,6-4.22,13.12-3.47,38.47.73a92.22,92.22,0,0,0,13,1.61c9.49.36,16-1.2,16-.89S65.27,6.9,56.73,7.14a80.51,80.51,0,0,1-11.86-.8c-10.75-1.2-14.13-2.29-23.56-3A65.31,65.31,0,0,0,8.8,3.91,19.85,19.85,0,0,0,4.37,5C1.75,5.93.1,7.22,0,7.06Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow"
          d="M157.12,7.06c.12-.21-2.78-2.74-4.51-4-6-4.22-13.12-3.47-38.47.73A92.1,92.1,0,0,1,101.2,5.44c-9.49.36-16-1.2-16-.89s6.65,2.35,15.2,2.59a80.31,80.31,0,0,0,11.85-.8c10.75-1.2,14.13-2.29,23.56-3a65.31,65.31,0,0,1,12.51.57,19.94,19.94,0,0,1,4.44,1C155.37,5.93,157,7.22,157.12,7.06Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow"
          d="M55.31,2.84c-.08.37,1.67,1.33,3.44,1.76a13.42,13.42,0,0,0,3.92.24,24.12,24.12,0,0,0,3.66-.36A34.86,34.86,0,0,0,71.62,3S66.68,3.9,66,3c-.57-.67-.22-1.35-.77-1.76-.85-.63-2.09.71-4.09,1.4C58,3.73,55.41,2.39,55.31,2.84Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow"
          d="M101.76,2.84c.09.37-1.67,1.33-3.43,1.76a13.51,13.51,0,0,1-3.93.24,24.23,24.23,0,0,1-3.66-.36A34.86,34.86,0,0,1,85.45,3h0s4.94.88,5.68,0c.56-.67.21-1.35.76-1.76C92.74.63,94,2,96,2.66,99.11,3.73,101.66,2.39,101.76,2.84Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow"
          d="M77.31,1.5c-1-1.08-2.76-.62-3-.54-.92.26-1.5.84-2.84.92a3.13,3.13,0,0,1-.68,0c-1.13-.16-1.4-.9-2.17-1a1.78,1.78,0,0,0-1.92,1.23A2,2,0,0,0,68.55,4c.49,0,.68-.41,1.72-.68.63-.16.79-.09.87,0,.4.33.09,1.25.43,1.44s.35-.15.76-.13a1.27,1.27,0,0,1,.72.32c.62.47,3.23,1.51,4.31.44A3.2,3.2,0,0,0,77.31,1.5Zm-7.8,1c-.13.26-.52.25-.75.18s-.57-.31-.53-.47.33-.19.49-.21.7-.09.82.21A.37.37,0,0,1,69.51,2.49Zm3.88.76a.6.6,0,0,1-.47.35.77.77,0,0,1-.64-.27C72,3,71.82,2.44,72,2.25s.45,0,.78.09a.89.89,0,0,1,.52.35A.65.65,0,0,1,73.39,3.25Zm1.87,1.62a.92.92,0,0,1-.72-.12c-.36-.21-.69-.68-.59-.89s.63-.13.94,0,.81.51.75.76S75.37,4.84,75.26,4.87Zm1.33-1.44c-.13.23-.44.23-.62.23a1.58,1.58,0,0,1-1.13-.55c-.27-.31-.53-.85-.38-1s.13-.09.67,0c.79.2,1.19.3,1.37.62A.79.79,0,0,1,76.59,3.43Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow"
          d="M79.82,1.5c1-1.08,2.76-.62,3-.54a10,10,0,0,0,2.84.92,3.12,3.12,0,0,0,.68,0c1.13-.16,1.4-.9,2.17-1a1.78,1.78,0,0,1,1.92,1.23A2,2,0,0,1,88.57,4c-.49,0-.67-.41-1.72-.68-.63-.16-.79-.09-.87,0-.4.33-.08,1.25-.42,1.44s-.35-.15-.77-.13a1.31,1.31,0,0,0-.72.32c-.62.47-3.22,1.51-4.31.44A3.2,3.2,0,0,1,79.82,1.5Zm7.8,1c.13.26.52.25.75.18s.56-.31.53-.47S88.56,2,88.41,2s-.71-.09-.82.21A.37.37,0,0,0,87.62,2.49Zm-3.89.76a.61.61,0,0,0,.48.35.78.78,0,0,0,.64-.27c.3-.32.45-.89.27-1.08s-.45,0-.78.09a.89.89,0,0,0-.52.35A.73.73,0,0,0,83.73,3.25ZM81.86,4.87a.91.91,0,0,0,.72-.12c.36-.21.7-.68.59-.89s-.63-.13-.94,0-.81.51-.75.76S81.75,4.84,81.86,4.87ZM80.54,3.43c.12.23.43.23.61.23a1.55,1.55,0,0,0,1.13-.55c.27-.31.53-.85.39-1s-.13-.09-.67,0c-.79.2-1.19.3-1.38.62A.79.79,0,0,0,80.54,3.43Z"
          transform="translate(0 -0.28)" />
        <path class="styling-bow"
          d="M77.35,4.66A1.32,1.32,0,0,1,78,3.45v0a1.14,1.14,0,0,1-.54-1,1.1,1.1,0,0,1,1-1.2,1,1,0,0,1,1,1.11,1.18,1.18,0,0,1-.56,1v0a1.26,1.26,0,0,1,.69,1.17,1.2,1.2,0,0,1-1.16,1.31A1.14,1.14,0,0,1,77.35,4.66Zm1.83,0a1,1,0,0,0-.75-1,.92.92,0,0,0-.61.92.75.75,0,0,0,.68.86A.72.72,0,0,0,79.18,4.63ZM77.91,2.38a.84.84,0,0,0,.65.83.85.85,0,0,0,.52-.82c0-.36-.16-.73-.57-.73S77.91,2,77.91,2.38Z"
          transform="translate(0 -0.28)" />
      </svg>
    </div>

  </div>

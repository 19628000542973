<div id="datenschutz-container" class="datenschutz-container">

  <div class="exit-cross-container">
    <img src="/assets/Startseite/times-solid.svg" class="exit-cross" id="datenschutzCloseButton">
  </div>

  <h1 class="header">
    Datenschutz
  </h1>


  <div class="content">
    <br>EU-STREITSCHLICHTUNG
    <br>
    <br>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über
    die Online-Streitbeilegungsplattform (OS-Plattform) informieren. Verbraucher haben die Möglichkeit, Beschwerden an
    die
    Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=121373230 zu richten.
    Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
    <br>
    <br>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
    Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
    <br>
    <br>Haftung für Inhalte dieser Webseite:
    <br>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen
    bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen,
    speziell für jene die seitens Dritter bereitgestellt wurden.
    <br>
    <br>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu kontaktieren,
    Sie finden die Kontaktdaten im Impressum.
    <br>
    <br>Haftung für Links auf dieser Webseite:
    <br>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für
    verlinkte Websites besteht laut § 17 ECG für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und
    haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn
    uns Rechtswidrigkeiten bekannt werden. Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie
    uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
    <br>
    <br>Urheberrechtshinweis:
    <br>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig,
    werden
    wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen. Sollten Sie auf dieser Webseite
    Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
    <br>
    <br>Bildernachweis:
    <br>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt. Die Bilderrechte liegen bei
    den folgenden Fotografen: Angelo Jantscher Datenschutzerklärung Inhaltsverzeichnis
    <br>
    <br>
    <br>
    <br>
    <br>DATENSCHUTZ
    <br>
    <br>Automatische Datenspeicherung
    <br>Cookies
    <br>Speicherung persönlicher Daten
    <br>Rechte laut Datenschutzgrundverordnung
    <br>Auswertung des Besucherverhaltens
    <br>Google Maps Datenschutzerklärung
    <br>Google Fonts Datenschutzerklärung
    <br>Google Fonts Lokal Datenschutzerklärung
    <br>YouTube Datenschutzerklärung
    <br>Datenschutz
    <br>
    <br>Wir haben diese Datenschutzerklärung (Fassung 01.01.1970-121373230) verfasst, um Ihnen gemäß der Vorgaben der
    Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und
    welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
    <br>
    <br>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der
    Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
    <br>
    <br>Automatische Datenspeicherung:
    <br>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so
    auch
    auf dieser Webseite.
    <br>
    <br>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese
    Webseite
    gespeichert ist) automatisch Daten wie
    <br>
    <br>die Adresse (URL) der aufgerufenen Webseite
    <br>Browser und Browserversion
    <br>das verwendete Betriebssystem
    <br>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
    <br>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird
    <br>Datum und Uhrzeit
    <br>in Dateien (Webserver-Logfiles).
    <br>
    <br>
    <br>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese
    Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten
    eingesehen werden.
    <br>
    <br>COOKIES
    <br>
    <br>Unsere Webseite verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern. Im Folgenden erklären wir, was
    Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.
    <br>
    <br>Was genau sind Cookies?
    <br>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
    Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien
    in
    Ihrem Browser. Diese Dateien nennt man Cookies.
    <br>
    <br>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden
    Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt.
    HTTP-Cookies sind kleine Dateien, die von unserer Webseite auf Ihrem Computer gespeichert werden. Diese
    Cookie-Dateien
    werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht aus einem
    Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben
    werden.
    <br>
    <br>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
    Seiteneinstellungen.
    Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite
    zurück. Dank der Cookies weiß unsere Webseite, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind.
    In
    einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in
    einer
    einzigen Datei gespeichert.
    <br>
    <br>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
    unserer
    Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie
    ist
    individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein
    paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner
    oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.
    <br>
    <br>So können zum Beispiel Cookie-Daten aussehen:
    <br>Name: _ga
    <br>Wert: GA1.2.1326744211.152121373230-9
    <br>Verwendungszweck: Unterscheidung der Webseitenbesucher
    <br>Ablaufdatum: nach 2 Jahren
    <br>
    <br>Diese Mindestgrößen sollte ein Browser unterstützen können:
    <br>Mindestens 4096 Bytes pro Cookie
    <br>Mindestens 50 Cookies pro Domain
    <br>Mindestens 3000 Cookies insgesamt
    <br>Welche Arten von Cookies gibt es?
    <br>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den
    folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen
    Arten
    von HTTP-Cookies eingehen.
    <br>
    <br>Man kann 4 Arten von Cookies unterscheiden:
    <br>Unerlässliche Cookies
    <br>Diese Cookies sind nötig, um grundlegende Funktionen der Webseite sicherzustellen. Zum Beispiel braucht es diese
    Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur
    Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster
    schließt.
    <br>
    <br>Zweckmäßige Cookies
    <br>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden
    mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Webseite bei verschiedenen Browsern gemessen.
    <br>
    <br>Zielorientierte Cookies
    <br>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte,
    Schriftgrößen oder Formulardaten gespeichert.
    <br>
    <br>Werbe-Cookies
    <br>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu
    liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
    <br>
    <br>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen
    möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.
    <br>
    <br>Wie kann ich Cookies löschen?
    <br>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
    Webseite die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise
    zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.
    <br>
    <br>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen
    ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:
    <br>
    <br>Chrome: Cookies in Chrome löschen, aktivieren und verwalten
    <br>Safari: Verwalten von Cookies und Websitedaten mit Safari
    <br>Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
    <br>Internet Explorer: Löschen und Verwalten von Cookies
    <br>Microsoft Edge: Löschen und Verwalten von Cookies
    <br>
    <br>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
    informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das
    Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in
    Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines Chrome
    Browsers.
    <br>
    <br>Wie sieht es mit meinem Datenschutz aus?
    <br>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies
    eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche
    Reaktionen
    auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des
    Telekommunikationsgesetzes (TKG).
    <br>
    <br>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir
    https://tools.ietf.org/html/rfc6265, dem Request for Comments der Internet Engineering Task Force (IETF) namens
    “HTTP
    State Management Mechanism”.
    <br>
    <br>SPEICHERUNG PERSÖNLICHER DATEN
    <br>
    <br>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
    E-Mail-Adresse,
    Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden
    von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt
    und nicht an Dritte weitergegeben.
    <br>
    <br>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich
    wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre
    persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen
    von
    rechtswidrigem Verhalten eingesehen werden.
    <br>
    <br>Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere
    Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt
    per E-Mail zu übermitteln.
    <br>
    <br>RECHTE LAUT DATENSCHUTZGRUNDVERORDNUNG
    <br>
    <br>Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen Datenschutzgesetzes (DSG) grundsätzlich
    die
    folgende Rechte zu:
    <br>
    <br>Recht auf Berichtigung (Artikel 16 DSGVO)
    <br>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
    <br>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
    <br>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung
    personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
    <br>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
    <br>Widerspruchsrecht (Artikel 21 DSGVO)
    <br>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden
    Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
    <br>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
    datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde
    beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite Sie unter https://www.dsb.gv.at/ finden.
    <br>
    <br>AUSWERTUNG DES BESUCHERVERHALTENS
    <br>
    <br>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser
    Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem
    Verhalten
    auf dieser Website nicht auf Ihre Person schließen.
    <br>
    <br>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden
    Datenschutzerklärung.
    <br>
    <br>GOOGLE MAPS DATENSCHUTZERKLÄRUNG
    <br>
    <br>Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen
    Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit
    Google Maps können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch die
    Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier wollen wir
    nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen, welche Daten
    gespeichert werden und wie Sie dies unterbinden können.
    <br>
    <br>Was ist Google Maps?
    <br>Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein
    Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn
    Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die
    Firma
    angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts mittels HTML-Code in eine
    Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild.
    Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.
    <br>
    <br>Warum verwenden wir Google Maps auf unserer Webseite?
    <br>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer
    Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen
    Standorten liefern. Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer
    den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen
    Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres
    Kundenservice.
    <br>
    <br>Welche Daten werden von Google Maps gespeichert?
    <br>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und
    speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw.
    Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese
    Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber
    keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden haben, setzt Google mindestens ein Cookie
    (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in
    erster
    Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.
    <br>
    <br>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:
    <br>Name: NID
    <br>Wert: 188=h26c1Ktha7fCQTx8rXgLyATyITJ121373230-5
    <br>Verwendungszweck: NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des
    Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit
    Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google
    benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu sammeln.
    <br>Ablaufdatum: nach 6 Monaten
    <br>
    <br>Anmerkung: Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei
    der Verwendung von Cookies sind Veränderungen nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine
    eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.
    <br>
    <br>Wie lange und wo werden die Daten gespeichert?
    <br>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in
    Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo
    sich die Google-Rechenzentren befinden: https://www.google.com/about/datacenters/inside/locations/?hl=de
    <br>
    <br>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden
    vor
    etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es
    zum
    Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm legt, bleiben die Daten
    ziemlich sicher trotzdem geschützt.
    <br>
    <br>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die
    Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel
    Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten
    löscht.
    <br>
    <br>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
    <br>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur
    Standortbestimmung und Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert
    und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen.
    Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und
    App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“.
    Hier können Sie die Aktivitäten ein- oder ausschalten.
    <br>
    <br>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem
    welchen Browser Sie verwenden, funktioniert dies immer etwas anders. Die folgenden Anleitungen zeigen, wie Sie
    Cookies
    in Ihrem Browser verwalten:
    <br>
    <br>Chrome: Cookies in Chrome löschen, aktivieren und verwalten
    <br>Safari: Verwalten von Cookies und Websitedaten mit Safari
    <br>Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
    <br>Internet Explorer: Löschen und Verwalten von Cookies
    <br>Microsoft Edge: Löschen und Verwalten von Cookies
    <br>
    <br>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
    informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es
    erlauben oder nicht.
    <br>
    <br>Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
    Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf
    https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI. Wenn Sie mehr über die Datenverarbeitung von Google
    erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter
    https://policies.google.com/privacy?hl=de.
    <br>
    <br>GOOGLE FONTS DATENSCHUTZERKLÄRUNG
    <br>
    <br>Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den
    europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle
    Google-Dienste verantwortlich.
    <br>
    <br>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters
    werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die
    Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und
    Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine
    Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden.
    Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese
    Daten
    sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.
    <br>
    <br>Was sind Google Fonts?
    <br>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die Google Ihren Nutzern
    kostenlos zu Verfügung stellen.
    <br>
    <br>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der
    Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.
    <br>
    <br>Warum verwenden wir Google Fonts auf unserer Webseite?
    <br>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen
    Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle
    Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die
    Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße
    für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme
    (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche
    Fehler
    können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN)
    gibt
    es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google
    Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen
    Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts
    also,
    damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.
    <br>
    <br>Welche Daten werden von Google gespeichert?
    <br>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
    externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse
    unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von
    Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht
    übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.
    <br>
    <br>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die
    gesammelten
    Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht
    Google
    auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen
    Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der
    BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice
    BigQuery,
    um große Datenmengen untersuchen und bewegen zu können.
    <br>
    <br>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie
    Spracheinstellungen,
    IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die
    Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von
    Google nicht eindeutig kommuniziert.
    <br>
    <br>Wie lange und wo werden die Daten gespeichert?
    <br>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU
    angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet
    ist
    eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
    <br>
    <br>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
    Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie
    nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten
    wieder.
    Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen
    und
    das Design zu verbessern.
    <br>
    <br>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
    <br>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten
    werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie
    den
    Google-Support auf https://support.google.com/?hl=de&tid=121373230 kontaktieren. Datenspeicherung verhindern Sie in
    diesem Fall nur, wenn Sie unsere Seite nicht besuchen.
    <br>
    <br>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können
    also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu
    Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=121373230. Dort geht
    zwar
    Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung
    sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten
    zu
    bekommen.
    <br>
    <br>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf
    https://www.google.com/intl/de/policies/privacy/ nachlesen.
    <br>
    <br>GOOGLE FONTS LOKAL DATENSCHUTZERKLÄRUNG
    <br>
    <br>Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das Unternehmen
    Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben die
    Google-Schriftarten lokal, d.h. auf unserem Webserver – nicht auf den Servern von Google – eingebunden. Dadurch gibt
    es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder Speicherung.
    <br>
    <br>Was sind Google Fonts?
    <br>Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis mit
    über 800 Schriftarten, die Google kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf
    den eigenen Server hochzuladen. Doch um diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden,
    haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und
    senden keine Daten an Google Fonts weiter.
    <br>
    <br>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können
    also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu
    Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=121373230.
    <br>
    <br>YOUTUBE DATENSCHUTZERKLÄRUNG
    <br>
    <br>Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir Ihnen interessante Videos direkt auf
    unserer
    Seite präsentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist. Betrieben wird das
    Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Website eine Seite
    aufrufen, die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser automatisch mit den Servern von YouTube
    bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten übertragen. Für die gesamte Datenverarbeitung
    im
    europäischen Raum ist Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich.
    <br>
    <br>Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet werden, warum wir YouTube-Videos
    eingebunden haben und wie Sie Ihre Daten verwalten oder löschen können.
    <br>
    <br>Was ist YouTube?
    <br>Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. Über die
    letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos auf unserer
    Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut
    haben.
    <br>
    <br>Warum verwenden wir YouTube-Videos auf unserer Webseite?
    <br>YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bemüht, Ihnen die
    bestmögliche User-Erfahrung auf unserer Webseite zu bieten. Und natürlich dürfen interessante Videos dabei nicht
    fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben unseren Texten und Bildern weiteren
    hilfreichen
    Content zur Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die eingebetteten Videos
    leichter
    gefunden. Auch wenn wir über Google Ads
    <br>Werbeanzeigen schalten, kann Google – dank der gesammelten Daten – diese Anzeigen wirklich nur Menschen zeigen,
    die sich für unsere Angebote interessieren.
    <br>
    <br>Welche Daten werden von YouTube gespeichert?
    <br>Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein
    Cookie,
    das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann YouTube Ihre
    Interaktionen auf unserer Webseite meist mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie
    Sitzungsdauer, Absprungrate, ungefährer Standort, technische Informationen wie Browsertyp, Bildschirmauflösung oder
    Ihr Internetanbieter. Weitere Daten können Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten über Social
    Media oder das Hinzufügen zu Ihren Favoriten auf YouTube sein.
    <br>
    <br>Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet sind, speichert Google Daten mit einer
    eindeutigen Kennung, die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise Ihre bevorzugte
    Spracheinstellung beibehalten. Aber viele Interaktionsdaten können nicht gespeichert werden, da weniger Cookies
    gesetzt werden.
    <br>
    <br>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits
    Cookies, die ohne angemeldetes YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit angemeldetem
    Account gesetzt werden. Die Liste kann keinen Vollständigkeitsanspruch erheben, weil die Userdaten immer von den
    Interaktionen auf YouTube abhängen.
    <br>
    <br>Name: YSC
    <br>Wert: b9-CV6ojI5Y121373230-1
    <br>Verwendungszweck: Dieses Cookie registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu
    speichern.
    <br>Ablaufdatum: nach Sitzungsende
    <br>
    <br>Name: PREF
    <br>Wert: f1=50000000
    <br>Verwendungszweck: Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt über PREF Statistiken,
    wie Sie YouTube-Videos auf unserer Webseite verwenden.
    <br>Ablaufdatum: nach 8 Monaten
    <br>
    <br>Name: GPS
    <br>Wert: 1
    <br>Verwendungszweck: Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um den GPS-Standort zu
    tracken.
    <br>Ablaufdatum: nach 30 Minuten
    <br>
    <br>Name: VISITOR_INFO1_LIVE
    <br>Wert: 95Chz8bagyU
    <br>Verwendungszweck: Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem
    YouTube-Video) zu schätzen.
    <br>Ablaufdatum: nach 8 Monaten
    <br>
    <br>Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto angemeldet sind:
    <br>
    <br>Name: APISID
    <br>Wert: zILlvClZSkqGsSwI/AU1aZI6HY7121373230-
    <br>Verwendungszweck: Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu erstellen. Genützt werden
    die Daten für personalisierte Werbeanzeigen.
    <br>Ablaufdatum: nach 2 Jahren
    <br>
    <br>Name: CONSENT
    <br>Wert: YES+AT.de+20150628-20-0
    <br>
    <br>Verwendungszweck: Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung unterschiedlicher
    Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen und Userdaten vor unbefugten Angriffen
    zu schützen. Ablaufdatum: nach 19 Jahren
    <br>
    <br>Name: HSID
    <br>Wert: AcRwpgUik9Dveht0I
    <br>Verwendungszweck: Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu erstellen. Diese Daten
    helfen personalisierte Werbung anzeigen zu können.
    <br>Ablaufdatum: nach 2 Jahren
    <br>
    <br>Name: LOGIN_INFO
    <br>Wert: AFmmF2swRQIhALl6aL…
    <br>Verwendungszweck: In diesem Cookie werden Informationen über Ihre Login-Daten gespeichert.
    <br>Ablaufdatum: nach 2 Jahren
    <br>
    <br>Name: SAPISID
    <br>Wert: 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
    <br>Verwendungszweck: Dieses Cookie funktioniert, indem es Ihren Browser und Ihr Gerät eindeutig identifiziert. Es
    wird verwendet, um ein Profil über Ihre Interessen zu erstellen.
    <br>Ablaufdatum: nach 2 Jahren
    <br>
    <br>Name: SID
    <br>Wert: oQfNKjAsI121373230-
    <br>Verwendungszweck: Dieses Cookie speichert Ihre Google-Konto-ID und Ihren letzten Anmeldezeitpunkt in digital
    signierter und verschlüsselter Form.
    <br>Ablaufdatum: nach 2 Jahren
    <br>
    <br>
    <br>Name: SIDCC
    <br>Wert: AN0-TYuqub2JOcDTyL
    <br>Verwendungszweck: Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und welche Werbung Sie vor
    dem Besuch auf unserer Seite möglicherweise gesehen haben.
    <br>Ablaufdatum: nach 3 Monaten
    <br>
    <br>Wie lange und wo werden die Daten gespeichert?
    <br>Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den Google-Servern gespeichert. Die meisten
    dieser Server befinden sich in Amerika. Unter https://www.google.com/about/datacenters/inside/locations/?hl=de sehen
    Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten sind auf den Servern verteilt. So sind die Daten
    schneller abrufbar und vor Manipulation besser geschützt.
    <br>
    <br>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten können Sie jederzeit löschen, andere
    werden automatisch nach einer begrenzten Zeit gelöscht und wieder andere werden von Google über längere Zeit
    gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem
    Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen. Auch wenn Sie nicht in einem
    Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
    löschen.
    <br>
    <br>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
    <br>Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der 2019 eingeführten automatischen
    Löschfunktion von Standort- und Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung – entweder 3
    oder
    18 Monate gespeichert und dann gelöscht.
    <br>
    <br>Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren Browser so konfigurieren, dass Cookies
    von
    Google gelöscht bzw. deaktiviert werden. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf
    unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
    <br>
    <br>Chrome: Cookies in Chrome löschen, aktivieren und verwalten
    <br>Safari: Verwalten von Cookies und Websitedaten mit Safari
    <br>Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
    <br>Internet Explorer: Löschen und Verwalten von Cookies
    <br>Microsoft Edge: Löschen und Verwalten von Cookies
    <br>
    <br>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
    informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es
    erlauben oder nicht. Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame Datenschutzerklärung.
    Wenn Sie mehr über den Umgang mit Ihren Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter
    https://policies.google.com/privacy?hl=de.
    <br>
    <br>YOUTUBE ABONNIEREN BUTTON DATENSCHUTZERKLÄRUNG
    <br>
    <br>Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl. „Subscribe-Button“) eingebaut. Sie erkennen
    den Button meist am klassischen YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in weißer Schrift die Wörter
    „Abonnieren“ oder „YouTube“ und links davon das weiße „Play-Symbol“. Der Button kann aber auch in einem anderen
    Design
    dargestellt sein.
    <br>
    <br>Unser YouTube-Kanal bietet Ihnen immer wieder lustige, interessante oder spannende Videos. Mit dem eingebauten
    „Abonnieren-Button“ können Sie unseren Kanal direkt von unserer Webseite aus abonnieren und müssen nicht eigens die
    YouTube-Webseite aufrufen. Wir wollen Ihnen somit den Zugang zu unserem umfassenden Content so einfach wie möglich
    machen. Bitte beachten Sie, dass YouTube dadurch Daten von Ihnen speichern und verarbeiten kann.
    <br>
    <br>Wenn Sie auf unserer Seite einen eingebauten Abo-Button sehen, setzt YouTube – laut Google – mindestens ein
    Cookie. Dieses Cookie speichert Ihre IP-Adresse und unsere URL. Auch Informationen über Ihren Browser, Ihren
    ungefähren Standort und Ihre voreingestellte Sprache kann YouTube so erfahren. Bei unserem Test wurden folgende vier
    Cookies gesetzt, ohne bei YouTube angemeldet zu sein:
    <br>
    <br>Name: YSC
    <br>Wert: b9-CV6ojI5121373230Y
    <br>Verwendungszweck: Dieses Cookie registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu
    speichern.
    <br>Ablaufdatum: nach Sitzungsende
    <br>
    <br>Name: PREF
    <br>Wert: f1=50000000
    <br>Verwendungszweck: Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt über PREF Statistiken,
    wie Sie YouTube-Videos auf unserer Webseite verwenden.
    <br>Ablaufdatum: nach 8 Monate
    <br>
    <br>Name: GPS
    <br>Wert: 1
    <br>Verwendungszweck: Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um den GPS-Standort zu
    tracken.
    <br>Ablaufdatum: nach 30 Minuten
    <br>
    <br>Name: VISITOR_INFO1_LIVE
    <br>Wert: 12137323095Chz8bagyU
    <br>Verwendungszweck: Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem
    YouTube-Video) zu schätzen.
    <br>Ablaufdatum: nach 8 Monaten
    <br>
    <br>Anmerkung: Diese Cookies wurden nach einem Test gesetzt und können nicht den Anspruch auf Vollständigkeit
    erheben.
    <br>
    <br>Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube viele Ihrer Handlungen/Interaktionen auf unserer
    Webseite mit Hilfe von Cookies speichern und Ihrem YouTube-Konto zuordnen. YouTube bekommt dadurch zum Beispiel
    Informationen wie lange Sie auf unserer Seite surfen, welchen Browsertyp Sie verwenden, welche Bildschirmauflösung
    Sie
    bevorzugen oder welche Handlungen Sie ausführen.
    <br>
    <br>YouTube verwendet diese Daten zum einen um die eigenen Dienstleistungen und Angebote zu verbessern, zum anderen
    um
    Analysen und Statistiken für Werbetreibende (die Google Ads verwenden) bereitzustellen.
    <br>
    <br>Quelle: Erstellt mit dem Datenschutz Generator von AdSimple® Online Marketing in Kooperation mit bauguide.at
    <br>
  </div>
  <br>

</div>

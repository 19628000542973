import { Component } from '@angular/core';
import { Animator, ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { TheArtistAnimator } from './TheArtistAnimator';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { ScrollToStep } from 'src/app/components/animation-manager/scroll-to/ScrollToStep';
import { WellKnownHomeScrollToIds } from '../../WellKnownHomeScrollToIds';

@Component({
  selector: 'app-the-artist',
  templateUrl: './the-artist.component.html',
  styleUrls: ['./the-artist.component.scss']
})
@Animator(new TheArtistAnimator())
export class TheArtistComponent extends ShellAngularComponent<TheArtistComponent>
{
  private mAdditionalPart: HTMLElement;
  public buttontext: string;

  public ScrollToManager: ScrollToManager;

  constructor(scrollToManagerService: ScrollToManagerService) 
  {
    super();

    this.ScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);
  }

  public async Initialize(): Promise<void>
  {
    this.mAdditionalPart = document.getElementById('additionalpart');
    this.buttontext = 'MEHR LESEN';

    const container = document.getElementById('about-section');

    this.ScrollToManager
      .AddPart(WellKnownHomeScrollToIds.TheArtist)
      .AddStep(new ScrollToStep("Der Künstler", container));

    await super.Initialize();
  }

  ToggleAdditionalPart()
  {
    const isactive = this.mAdditionalPart.classList.toggle('hidden-part');

    if (isactive)
    {
      this.buttontext = 'MEHR LESEN';
    }
    else
    {
      this.buttontext = 'WENIGER LESEN';
    }
  }
}
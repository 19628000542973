
<div id="contact-container" class="contact-container">
    <div class="contact-left">
        <h1 class="left-header">Haben sie eine Story?</h1>
        <p class="left-text">über das Kontaktformular können Sie uns Ihre Erfahrungen mit dem Büchlein schicken.</p>
        <div class="form-left">
            <form [formGroup]="aFormGroup" class="form-container" #formDirective="ngForm">
                <mat-form-field class="forname-field" appearance="fill">
                    <mat-label>VORNAME</mat-label>
                    <input matInput formControlName="ForName" required>
                </mat-form-field>
                <mat-form-field class="surname-field" appearance="fill">
                    <mat-label>NACHNAME</mat-label>
                    <input matInput formControlName="SurName" required>
                </mat-form-field>
                <mat-form-field class="email-field" appearance="fill">
                    <mat-label>E-MAIL ADRESSE</mat-label>
                    <input matInput placeholder="pat@example.com" formControlName="Email" required>
                    <mat-error *ngIf="Email.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
                <mat-form-field class="message-field" appearance="fill">
                    <mat-label>NACHRICHT</mat-label>
                    <textarea matTextareaAutosize matAutosizeMinRows="8" matAutosizeMaxRows="8" matInput formControlName="Message"></textarea>
                </mat-form-field>
                <button class="submit-button" mat-raised-button color="primary" (click)="OnSubmit();">Senden</button>
            </form>
        </div>
    </div>
    <div class="contact-right">
        <img class="img-rose" src="/assets/Startseite/min/DQDL_SS_33_21.png" alt="Rose">
    </div>
</div>

import { Injectable } from '@angular/core';
import { Dictionary } from 'typescript-collections';
import { ScrollToManager } from './scroll-to/ScrollToManager';
import { SiteId } from './scroll-to/SiteId';

@Injectable({
  providedIn: 'root'
})
export class ScrollToManagerService {

  private mManagers : Dictionary<SiteId, ScrollToManager>;

  constructor() 
  { 
    this.mManagers = new Dictionary<SiteId, ScrollToManager>();
  }

  public GetScrollToManager(siteId: SiteId) : ScrollToManager
  {
    let maybeManager = this.mManagers.getValue(siteId);

    if(maybeManager !== undefined)
    {
      return maybeManager;
    }

    maybeManager = new ScrollToManager(siteId);
    this.mManagers.setValue(siteId, maybeManager);
    return maybeManager;
  }
}

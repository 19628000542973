import { HomeComponent } from './home.component';
import { ViewDescription, ViewRoute } from '@webfacture/simplicitas-shell';


@ViewDescription
export class HomeViewDescription
{
    public static ViewRoute: ViewRoute = new ViewRoute("Home", HomeComponent, false);

    ViewRoute: ViewRoute = HomeViewDescription.ViewRoute;
}


import { MediaQueryAnimator } from '@webfacture/simplicitas-shell';
import { LandingSectionComponent } from './landing-section.component';
import { ScrollTrigger } from 'gsap/all';
import { gsap } from 'gsap';
import { WellKnownHomeScrollToIds } from './../../WellKnownHomeScrollToIds';

export class LandingSectionAnimator extends MediaQueryAnimator<LandingSectionComponent>
{
    Dispose()
    {

    }

    InitializeAnimations(): void
    {
        gsap.registerPlugin(ScrollTrigger);

        if (window.innerWidth > 800)
        {
            this._InitializeDesktop();
        }
    }

    private _InitializeDesktop()
    {
        const container = document.getElementById('landing-section-container');

        const scrollToStep = this.ViewModel.ScrollToManager.GetFirstStepOfPart(WellKnownHomeScrollToIds.LandingSection);

        gsap.to(document.body,
            {
                scrollTrigger: {
                    end: "bottom 50%",
                    trigger: container,
                    onEnterBack: () => 
                    {
                        this.ViewModel.ScrollToManager.UpdateCurrentStep(scrollToStep);
                    }
                }
            });
    }

}
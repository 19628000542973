import { Component, Input } from '@angular/core';
import { ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { fromEvent, merge } from 'rxjs';
import { WaterDropRefresherService } from './water-drop-refresher.service';

declare var $: any;

@Component({
  selector: 'app-water-drop-effect',
  templateUrl: './water-drop-effect.component.html',
  styleUrls: ['./water-drop-effect.component.scss']
})
export class WaterDropEffectComponent extends ShellAngularComponent<WaterDropEffectComponent>
{
  @Input()
  public Resolution: number = 1024;

  @Input()
  public Multiplier: number = 0.1;

  @Input()
  public QuerySelector: string;

  constructor(refreshService: WaterDropRefresherService)
  {
    super();

    if(this._CheckForWebGLSupport())
    {
      this.Disposables.Add(
        refreshService
          .CustomRefresh
          .subscribe((_) => this._UpdateRipple()));
    }
  }

  async Initialize(): Promise<void>
  {
    if((!this._CheckForWebGLSupport())
    // || (!this._CheckMinHardwareRequirements())
    || document.body.offsetWidth < 600 || window['safari'] !== undefined)
    {
      return;
    }

    // this.Disposables
    //   .Add(fromEvent(window, 'mousemove')
    //   .subscribe((ev: MouseEvent) => this._MouseMoved(ev)));

    // this.Disposables
    //   .Add(merge(
    //     fromEvent(window, 'touchmove'),
    //     fromEvent(window, 'touchstart'))
    //     .subscribe((ev: TouchEvent) => this._TouchEvent(ev)));

    // this.Disposables
    //     .Add(fromEvent(window, 'click')
    //     .subscribe((ev: MouseEvent) => this._MousePressed(ev)));

    // this.Disposables
    //     .Add(fromEvent(window, 'resize')
    //     .subscribe((_) => this._UpdateRipple()));

    // $(this.QuerySelector).ripples({
    //   imageUrl: '/assets/Startseite/WaterDropBackground.png',
    //   // resolution: 9192,
    //   resolution: this.Resolution,
    //   dropRadius: 20,
    //   perturbance: 0,
    //   interactive: false
    // });

    // $(this.QuerySelector).ripples('updateSize');
  }

  private _CheckMinHardwareRequirements() : boolean
  {
    let enoughtCores = true;
    let enoughMemory = true;
    var anyNavigator : any = navigator;
    if(anyNavigator['hardwareconcurrency'] !== undefined)
    {
      const cores = anyNavigator['hardwareconcurrency'];
      enoughtCores = cores > 2;
    }
    if(anyNavigator['deviceMemory'] !== undefined)
    {
      const gbMemory = anyNavigator['deviceMemory'];
      enoughMemory = gbMemory > 6;
    }

    return enoughMemory && enoughtCores;
  }

  private _CheckForWebGLSupport() : boolean
  {
    try
    {
      var canvas = document.createElement('canvas');
      return (canvas.getContext('webgl') !== undefined || canvas.getContext('experimental-webgl')) !== undefined;
     } catch(e)
     {
       return false;
     }
  }

  private _UpdateRipple()
  {
    // $(this.QuerySelector).ripples('updateSize');
  }

  private _TouchEvent(event: TouchEvent)
  {
    const touches = event.changedTouches;
    for(var i = 0; i < touches.length; i++)
    {
      $(this.QuerySelector).ripples('drop', touches[i].pageX + document.body.scrollLeft, touches[i].screenY + document.body.scrollTop, 20, 0.01 * this.Multiplier);
    }
  }

  private _MouseMoved(event: MouseEvent)
  {
    $(this.QuerySelector).ripples('drop', event.pageX + document.body.scrollLeft, event.y + document.body.scrollTop, 10, 0.0025 * this.Multiplier);
  }

  private _MousePressed(event: MouseEvent)
  {
    $(this.QuerySelector).ripples('drop', event.pageX + document.body.scrollLeft, event.y + document.body.scrollTop, 15, 0.014 * this.Multiplier);
  }


  public async Destroy() : Promise<void>
  {
    $(this.QuerySelector).ripples('destroy')
  }
}

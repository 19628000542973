<div id="impressum-container" class="impressum-container">

  <div class="exit-cross-container">
    <img src="/assets/Startseite/times-solid.svg" class="exit-cross" id="impressumCloseButton">
  </div>

  <h1 class="header">Impressum</h1>

  <div class="content">
    IMPRESSUM
    <br>
    <br>
    Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
    Offenlegungspflicht laut §25 Mediengesetz.
    <br>
    Haus der Künste
    ZVR: 1228171730
    <br>
    Gundersdorf 77, b,
    8511 St. Stefan ob Stainz,
    Österreich
    <br>
    Organschaftliche Vertreter
    Obmann/Obfrau: Maximilian Jantscher
    Obmann/Obfrau Stellvertreter: Angelo Lionardo Jantscher
    <br>
    Tel.: 06643008833
    E-Mail: artgallery3000@gmail.com
    <br>
    HAUS DER KÜNSTE VEREINSSTATUTEN:
    <br>
    <br>
    § 1: Name, Sitz und Tätigkeitsbereich (1) Der Verein führt den Namen „ Haus der Künste“.
    <br>
    (2) Er hat seinen Sitz in 8511 St. Stefan ob Stainz, Gundersdorf 77 und erstreckt seine
    Tätigkeit auf die ganze Welt.
    (3) Der Verein ist konfessionell und parteipolitisch neutral.
    <br>
    <br>
    <br>
    § 2: Zweck Der Verein, dessen Tätigkeit nicht auf Gewinn gerichtet ist, bezweckt die
    Förderung von Projekten und Personen in Kooperation mit Kunstuniversitäten,
    Bildungseinrichtungen, kulturellen Einrichtungen und der Kreativwirtschaft, sowie
    Partnerschaften mit sozialen und soziokulturellen Einrichtungen. Er ermöglicht neue Zugänge, Begegnungen,
    Auseinandersetzungen und kritische Reflektion von Menschen mit der Kunst, Kultur, universitären Einrichtungen und
    kreativen Berufen. Er fördert die Entfaltung der Neugierde, des kreativen Experiments und innovativer Denkansätze.
    Er ist
    eine Stätte der Offenheit, der Vielfalt und der Chancengleichheit und sieht sich in integrativer, sozialer und
    gesellschaftlicher Verantwortung. Er widmet sich auch der Einbindung von Projekten und Personen aus bildungsfernen
    Schichten sowie Personen mit besonderen Bedürfnissen. Er dient auch der Entwicklung von Schlüsselkompetenzen und
    der Integration/Inklusion. Er sieht sich als Schnittstelle zwischen Wissenschaft, Kunst und Gesellschaft.
    <br>
    <br>
    <br>
    § 3: Mittel zur Erreichung des Vereinszwecks (1) Der Vereinszweck soll durch die in den Abs. 2 und 3 angeführten
    ideellen
    und
    materiellen Mittel erreicht werden.
    (2) Als ideelle Mittel dienen
    - Durchführung und Abhaltung von Workshops, Kreativprogrammen, Vorträgen,
    Diskussionen und Veranstaltungen.
    - Die Herausgabe von Publikationen.
    - Der Kunst- und Kulturaustausch mit nationalen und internationalen Institutionen, die Kinder, Jugend– und
    Erwachsenenprogramme veranstalten.
    - Die Bildung eines Informationsnetzwerkes, im Sinne der europäischen und
    weltweiten österreichischen Kulturpolitik unter Einbeziehung der im In- und
    Ausland tätigen Institutionen, die sich mit künstlerischen oder wissenschaftlichen
    Kinder, Jugend– und Erwachsenenprogramme auseinandersetzen. (3) Die erforderlichen materiellen Mittel sollen
    aufgebracht werden durch:
    Mitgliedsbeiträge, Spenden, Sponsorleistungen und Subventionen.
    <br>
    <br>
    <br>
    § 4: Arten der Mitgliedschaft (1) Die Mitglieder des Vereins gliedern sich in ordentliche, außerordentliche und
    Ehrenmitglieder. (2) Ordentliche Mitglieder sind jene, die sich voll an der Vereinsarbeit beteiligen.
    Außerordentliche Mitglieder sind solche, die die Vereinstätigkeit in außer- gewöhnlicher Weise fördern.
    Ehrenmitglieder
    sind Personen, die hierzu wegen besonderer Verdienste um den Verein ernannt werden.
    <br>
    <br>
    <br>
    § 5: Erwerb der Mitgliedschaft (1) Mitglieder des Vereins können physischen Personen sowie juristische Personen und
    rechtsfähige Personengesellschaften werden. (2) Über die Aufnahme von ordentlichen und außerordentlichen Mitgliedern
    entscheidet der Vorstand. Die Aufnahme kann ohne Angabe von Gründen verweigert werden. (3) Bis zur Entstehung des
    Vereins erfolgt die vorläufige Aufnahme von ordentlichen
    und außerordentlichen Mitgliedern durch die Vereinsgründer. Diese Mitgliedschaft wird erst mit Entstehung des
    Vereins
    wirksam. (4) Die Ernennung zum Ehrenmitglied erfolgt auf Antrag des Vorstands durch die
    Generalversammlung.
    <br>
    <br>
    <br>
    § 6: Beendigung der Mitgliedschaft (1) Die Mitgliedschaft erlischt durch Tod, bei juristischen Personen und
    rechts-fähigen
    Personengesellschaften durch Verlust der Rechtspersönlichkeit, durch freiwilligen Austritt und durch Ausschluss. (2)
    Der
    Austritt kann nur zum 31. Dezember eines jeden Jahres erfolgen. Er muss
    dem Vorstand mindestens 4 Wochen vorher schriftlich, per Einschreiben, mitgeteilt werden. Es gilt das Datum der
    Postaufgabe. (3) Der Vorstand kann ein Mitglied ausschließen, wenn dieses trotz zweimaliger
    schriftlicher Mahnung unter Setzung einer angemessenen Nachfrist länger als
    sechs Monate mit der Zahlung der Mitgliedsbeiträge im Rückstand ist. Die Ver-pflichtung zur Zahlung der fällig
    gewordenen Mitgliedsbeiträge bleibt hiervon unberührt. (4) Der Ausschluss eines Mitglieds aus dem Verein kann vom
    Vorstand auch wegen
    grober Verletzung anderer Mitgliedspflichten und wegen unehrenhaften Verhaltens verfügt werden. (5) Die Aberkennung
    der Ehrenmitgliedschaft kann aus den im Abs. 4 genannten
    Gründen von der Generalversammlung über Antrag des Vorstands beschlossen
    werden.
    <br>
    <br>
    <br>
    § 7: Rechte und Pflichten der Mitglieder (1) Die Mitglieder sind berechtigt, an allen Veranstaltungen des Vereins
    teilzunehmen,
    außer es sind Veranstaltungen mit begrenzter Teilnehmerzahl. Hier gilt das Prinzip der Reihenfolge. (2) Das
    Stimmrecht in der Generalversammlung, sowie das aktive und passive
    Wahlrecht, stehen nur den ordentlichen und den Ehrenmitgliedern zu. (3) Jedes Mitglied ist berechtigt, vom Vorstand
    die
    Ausfolgung der Statuten zu
    verlangen. (4) Mindestens ein Zehntel der Mitglieder kann vom Vorstand die Einberufung einer
    Generalversammlung verlangen. (5) Die Mitglieder sind in jeder Generalversammlung vom Vorstand über die Tätigkeit
    und finanzielle Gebarung des Vereins zu informieren. Wenn mindestens ein Zehntel der Mitglieder dies unter Angabe
    von
    Gründen verlangt, hat der Vorstand den betreffenden Mitgliedern eine solche Information auch sonst binnen vier
    Wochen
    zu geben.
    (6) Die Mitglieder sind vom Vorstand über den geprüften Rechnungsabschluss (Rechnungslegung) zu informieren.
    Geschieht dies in der Generalversammlung, sind die Rechnungsprüfer einzubinden.
    (7) Die Mitglieder sind verpflichtet, die Interessen des Vereins nach Kräften zu
    fördern und alles zu unterlassen, wodurch das Ansehen und der Zweck des Vereins Abbruch erleiden könnte. Sie haben
    die Vereinsstatuten und die Beschlüsse der Vereinsorgane zu beachten. Die ordentlichen und außerordentlichen
    Mitglieder sind zur pünktlichen Zahlung der Mitgliedsbeiträge in der von der Generalversammlung beschlossenen Höhe
    verpflichtet.
    <br>
    <br>
    <br>
    § 8: Vereinsorgane Organe des Vereins sind die Generalversammlung, der Vorstand, die Rechnungs-prüfer und das
    Schiedsgericht.
    <br>
    <br>
    <br>
    § 9: Generalversammlung (1) Die Generalversammlung ist die „Mitgliederversammlung“ im Sinne des
    Vereinsgesetzes 2002. Eine ordentliche Generalversammlung findet jährlich
    statt. (2) Eine außerordentliche Generalversammlung findet auf a. Beschluss des Vorstands oder der ordentlichen
    Generalversammlung,
    b. schriftlichen Antrag von mindestens einem Zehntel der Mitglieder,
    c. Verlangen der Rechnungsprüfer (§ 21 Abs. 5 erster Satz VereinsG),
    d. Beschluss der/eines Rechnungsprüfer/s (§ 21 Abs. 5 zweiter Satz VereinsG, § 11
    Abs. 2 dritter Satz dieser Statuten),
    e. Beschluss eines gerichtlich bestellten Kurators (§ 11 Abs. 2 letzter Satz dieser Statuten) binnen vier Wochen
    statt. (3)
    Sowohl zu den ordentlichen wie auch zu den außerordentlichen General-versammlungen sind alle Mitglieder mindestens
    zwei Wochen vor dem Termin schriftlich einzuladen. Die Anberaumung der Generalversammlung hat unter Angabe der
    Tagesordnung zu erfolgen. Die Einberufung erfolgt durch den Vorstand, durch die/einen Rechnungsprüfer oder durch
    einen gerichtlich bestellten Kurator. (4) Anträge zur Generalversammlung sind mindestens siebenTage vor dem Termin
    der Generalversammlung beim Vorstand schriftlich einzureichen. (5) Gültige Beschlüsse – ausgenommen solche über
    einen Antrag auf Einberufung
    einer außerordentlichen Generalversammlung – können nur zur Tagesordnung
    gefasst werden. (6) Bei der Generalversammlung sind alle Mitglieder teilnahmeberechtigt. Stimm-berechtigt sind nur
    die
    ordentlichen und die Ehrenmitglieder. Jedes Mitglied hat eine Stimme. Die Übertragung des Stimmrechts auf ein
    anderes
    Mitglied im Wege einer schriftlichen Bevollmächtigung ist zulässig. (7) Die Generalversammlung ist ohne Rücksicht
    auf die
    Anzahl der Erschienenen
    beschlussfähig. (8) Die Wahlen und die Beschlussfassungen in der Generalversammlung erfolgen in
    der Regel mit einfacher Mehrheit der abgegebenen gültigen Stimmen. Beschlüsse, mit denen das Statut des Vereins
    geändert oder der Verein aufgelöst werden soll, bedürfen jedoch einer qualifizierten Mehrheit von zwei Dritteln der
    abgegebenen gültigen Stimmen. (9) Den Vorsitz in der Generalversammlung führt der/die Präsident/in, in
    dessen/deren Verhinderung sein/e/ ihr/e Stellvertreter/in. Wenn auch diese/r
    verhindert ist, so führt der/die Kassier/in den Vorsitz.
    <br>
    <br>
    <br>
    § 10: Aufgaben der Generalversammlung Der Generalversammlung sind folgende Aufgaben vorbehalten: a)
    Beschlussfassung über den Voranschlag;
    b) Entgegennahme und Genehmigung des Rechenschaftsberichts und des Rechnungsabschlusses unter Einbindung der
    Rechnungsprüfer;
    c) Wahl und Enthebung der Mitglieder des Vorstands und der Rechnungsprüfer;
    d) Genehmigung von Rechtsgeschäften zwischen Rechnungsprüfern und Verein;
    e) Entlastung des Vorstands;
    f) Festsetzung der Höhe der Mitgliedsbeiträge für die ordentlichen und außer-
    ordentlichen Mitglieder;
    g) Verleihung und Aberkennung der Ehrenmitgliedschaft;
    h) Beschlussfassung über Statutenänderungen und die freiwillige Auflösung des
    Vereins;
    i) Beratung und Beschlussfassung über sonstige auf der Tagesordnung stehende
    Fragen.
    <br>
    <br>
    <br>
    § 11: Vorstand (1) Der Vorstand besteht aus drei Mitgliedern, und zwar aus der/dem Präsident/in, Schriftführer/in,
    sowie
    Kassier/in. (2) Der Vorstand wird von der Generalversammlung gewählt. Der Vorstand hat bei
    Ausscheiden eines gewählten Mitglieds das Recht, an seine Stelle ein anderes
    wählbares Mitglied zu nominieren, wozu die nachträgliche Genehmigung in der
    nächstfolgenden Generalversammlung einzuholen ist. Fällt der Vorstand ohne
    Selbstergänzung durch Nominierung überhaupt oder auf unvorhersehbar lange
    Zeit aus, so ist jeder Rechnungsprüfer verpflichtet, unverzüglich eine außer-ordentliche Generalversammlung zum
    Zweck
    der Neuwahl eines Vorstands
    einzuberufen. Sollten auch die Rechnungsprüfer handlungsunfähig sein, hat
    jedes ordentliche Mitglied, das die Notsituation erkennt, unverzüglich die
    Bestellung eines Kurators beim zuständigen Gericht zu beantragen, der umgehend eine außerordentliche
    Generalversammlung einzuberufen hat. (3) Die Funktionsperiode des Vorstands beträgt drei Jahre; Wiederwahl ist
    möglich.
    Jede Funktion im Vorstand ist persönlich auszuüben. (4) Der Vorstand wird vom/von der/dem Präsident/in, bei
    Verhinderung von
    seinem/seiner/ihrem/ihrer Stellvertreter/in, schriftlich oder mündlich einberufen. Ist auch diese/r auf
    unvorhersehbar
    lange Zeit verhindert, darf jedes sonstige Vorstandsmitglied den Vorstand einberufen. (5) Der Vorstand ist
    beschlussfähig,
    wenn alle seine Mitglieder eingeladen wurden
    und mindestens die Hälfte von ihnen anwesend ist. (6) Der Vorstand fasst seine Beschlüsse mit einfacher
    Stimmenmehrheit. (7) Den Vorsitz führt der/die Präsident/in, bei Verhinderung sein/e/ihr/e Stell-vertreter/in. Ist
    auch
    diese/r verhindert, obliegt der Vorsitz der/die Kassier/in. (8) Außer durch den Tod und Ablauf der Funktionsperiode
    erlischt
    die Funktion
    eines Vorstandsmitglieds durch Enthebung und Rücktritt. (9) Die Generalversammlung kann jederzeit den gesamten
    Vorstand oder einzelne
    seiner Mitglieder entheben. Die Enthebung tritt mit Bestellung des neuen Vorstands bzw. Vorstandsmitglieds in Kraft.
    (10)
    Die Vorstandsmitglieder können jederzeit schriftlich ihren Rücktritt erklären.
    Die Rücktrittserklärung ist an den Vorstand, im Falle des Rücktritts des gesamten Vorstands an die
    Generalversammlung
    zu richten. Der Rücktritt wird erst mit Wahl bzw. Nominierung eines Nachfolgers wirksam.
    <br>
    <br>
    <br>
    § 12: Aufgaben des Vorstands Dem Vorstand obliegt die Leitung des Vereins. Er ist das „Leitungsorgan“ im Sinne
    des Vereinsgesetzes 2002. Ihm kommen alle Aufgaben zu, die nicht durch die
    Statuten einem anderen Vereinsorgan zugewiesen sind. In seinen Wirkungsbereich
    fallen insbesondere folgende Angelegenheiten: (1) Einrichtung eines den Anforderungen des Vereins entsprechenden
    Rechnungs- wesens mit laufender Aufzeichnung der Einnahmen/Ausgaben und Führung eines Vermögensverzeichnisses
    als Mindesterfordernis; (2) Erstellung des Jahresvoranschlags, des Rechenschaftsberichts und des
    Rechnungsabschlusses;
    (3) Vorbereitung und Einberufung der Generalversammlung in den Fällen des § 9
    Abs. 1 und Abs. 2 lit. a – c dieser Statuten; (4) Information der Vereinsmitglieder über die Vereinstätigkeit, die
    Vereinsgebarung und den geprüften Rechnungsabschluss; (5) Verwaltung des Vereinsvermögens; (6) Aufnahme und
    Ausschluss
    von ordentlichen und außerordentlichen Vereins-mitgliedern; (7) Aufnahme und Kündigung von Angestellten des Vereins.
    <br>
    <br>
    <br>
    § 13: Besondere Obliegenheiten einzelner Vorstandsmitglieder (1) Der/die Präsident/in führt die laufenden Geschäfte
    des
    Vereins. Der/die
    Schriftführer/in unterstützt den/die Präsident/in bei der Führung der Vereins- geschäfte. (2) Der/die Präsident/in
    vertritt
    den Verein nach außen. Schriftliche Ausfertigungen des Vereins bedürfen zu ihrer Gültigkeit der Unterschriften
    des/der
    Präsident/in und des Schriftführers/der Schriftführerin, in Geld- angelegenheiten (vermögenswerte Dispositionen)
    des/der
    Präsident/in und des Kassiers/der Kassierin. Rechts-geschäfte zwischen Vorstandsmitgliedern und Verein bedürfen der
    Zustimmung eines anderen Vorstandsmitglieds. (3) Rechtsgeschäftliche Bevollmächtigungen, den Verein nach außen zu
    vertreten
    bzw. für ihn zu zeichnen, können ausschließlich von den in Abs. 2 genannten
    Vorstandsmitgliedern erteilt werden. (4) Bei Gefahr im Verzug ist der/die Präsident/in berechtigt, auch in
    Angelegenheiten,
    die in den Wirkungsbereich der Generalversammlung oder des Vorstands fallen, unter eigener Verantwortung
    selbständig Anordnungen zu treffen; im Innenverhältnis bedürfen diese jedoch der nachträglichen Genehmigung durch
    das zuständige Vereinsorgan. (5) Der/die Präsident/in führt den Vorsitz in der Generalversammlung und im
    Vorstand. (6) Der/die Schriftführer/in führt die Protokolle der Generalversammlung und des
    Vorstands. (7) Der/die Kassier/in ist für die ordnungsgemäße Geldgebarung des Vereins
    verantwortlich. (8) Im Fall der Verhinderung des/der Präsident/in tritt an dessen/deren Stelle
    der/die Schriftführer/in als Vertreter/in.
    <br>
    <br>
    <br>
    § 14: Rechnungsprüfer (1) Zwei Rechnungsprüfer/innen werden von der Generalversammlung auf die
    Dauer von drei Jahren gewählt. Wiederwahl ist möglich. Die Rechnungsprüfer
    dürfen keinem Organ – mit Ausnahme der Generalversammlung – angehören,
    dessen Tätigkeit Gegenstand der Prüfung ist. (2) Den Rechnungsprüfern obliegen die laufende Geschäftskontrolle sowie
    die
    Prüfung der Finanzgebarung des Vereins im Hinblick auf die Ordnungsmäßigkeit
    der Rechnungslegung und die statutengemäße Verwendung der Mittel. Der
    Vorstand hat den Rechnungsprüfern die erforderlichen Unterlagen vorzulegen
    und die erforderlichen Auskünfte zu erteilen. Die Rechnungsprüfer haben dem
    Vorstand über das Ergebnis der Prüfung zu berichten. (3) Rechtsgeschäfte zwischen Rechnungsprüfern und Verein
    bedürfen der Ge- nehmigung durch die Generalversammlung. Im Übrigen gelten für die Rechnungs-prüfer die
    Bestimmungen des § 11 Abs. 8 bis 10 sinngemäß.
    <br>
    <br>
    <br>
    § 15: Schiedsgericht (1) Zur Schlichtung von allen aus dem Vereinsverhältnis entstehenden Streitigkeiten
    ist das vereinsinterne Schiedsgericht berufen. Es ist eine „Schlichtungseinrichtung“ im Sinne des Vereinsgesetzes
    2002 und
    kein Schiedsgericht nach den §§ 577 ff ZPO. (2) Das Schiedsgericht setzt sich aus drei ordentlichen
    Vereinsmitgliedern
    zusammen. Es wird derart gebildet, dass ein Streitteil dem Vorstand ein Mitglied
    als Schiedsrichter schriftlich namhaft macht. Über Aufforderung durch den
    Vorstand binnen sieben Tagen macht der andere Streitteil innerhalb von 14 Tagen seinerseits ein Mitglied des
    Schiedsgerichts namhaft. Nach Verständigung durch den Vorstand innerhalb von sieben Tagen wählen die namhaft
    gemachten Schiedsrichter binnen weiterer 14 Tage ein drittes ordentliches Mitglied zum/zur Vorsitzenden des
    Schiedsgerichts. Bei Stimmengleichheit entscheidet unter den Vorgeschlagenen das Los. Die Mitglieder des
    Schiedsgerichts dürfen keinem Organ – mit Ausnahme der Generalversammlung – angehören, dessen Tätigkeit
    Gegenstand der Streitigkeit ist. (3) Das Schiedsgericht fällt seine Entscheidung nach Gewährung beiderseitigen
    Gehörs bei Anwesenheit aller seiner Mitglieder mit einfacher Stimmenmehrheit.
    Es entscheidet nach bestem Wissen und Gewissen. Seine Entscheidungen sind
    vereinsintern endgültig.
    <br>
    <br>
    <br>
    § 16: Freiwillige Auflösung des Vereins (1) Die freiwillige Auflösung des Vereins kann nur in einer
    Generalversammlung
    und
    nur mit Zweidrittelmehrheit der abgegebenen gültigen Stimmen beschlossen werden. (2) Diese Generalversammlung hat
    auch – sofern Vereinsvermögen vorhanden ist –
    über die Abwicklung zu beschließen. Insbesondere hat sie einen Abwickler zu
    berufen und Beschluss darüber zu fassen, wem dieser das nach Abdeckung der
    Passiven verbleibende Vereinsvermögen zu übertragen hat. Dieses Vermögen soll, soweit dies möglich und erlaubt ist,
    einer Organisation zufallen, die gleiche
    oder ähnliche Zwecke wie dieser Verein verfolgt, sonst zur Verwendung für gemeinnützige oder mildtätige Zwecke im
    Sinne der §§ 34ff BAO.
    Quelle: Teils erstellt mit dem Impressum Generator von AdSimple® in Kooperation mit bauguide.at
    <br>
  </div>

</div>


import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ImagePickerItem } from './model/ImagePickerItem';
import { IImagePickerItemDataContext } from './model/IImagePickerItemDataContext';
import { Command, NotifyPropertyChanged, ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { fromEvent, Observable } from 'rxjs';

@Component({
  selector: 'app-img-picker',
  templateUrl: './img-picker.component.html',
  styleUrls: ['./img-picker.component.scss']
})
export class ImgPickerComponent extends ShellAngularComponent<ImgPickerComponent>
{
  @Input()
  public DataItemTemplate: TemplateRef<any>;

  @Input()
  @NotifyPropertyChanged
  public ItemSource: IImagePickerItemDataContext<any>[];

  @Output()
  public SelectedItemChanged: EventEmitter<any> = new EventEmitter<any>();

  public SelectedPickerItem: ImagePickerItem<any>;

  public NextItem: ImagePickerItem<any>;

  public PrevItem: ImagePickerItem<any>;

  @NotifyPropertyChanged
  public AvailableItems: ImagePickerItem<any>[];

  @ViewChild('imagePickerContainer')
  private mContainer: ElementRef<HTMLElement>;

  public Height: Observable<number>;

  public Width: number;

  private mItems: ImagePickerItem<any>[];

  constructor(
    private el:ElementRef,
    private mCdr: ChangeDetectorRef)
  {
    super();

    this.Disposables.Add(
      this.Property.Observe<IImagePickerItemDataContext<any>[]>(vm => vm.ItemSource)
        .pipe(filter(items => items != undefined))
        .subscribe(items => this._UpdateItems(items)));

    this.Disposables.Add(
      this.Property.Observe<ImagePickerItem<any>[]>(vm => vm.AvailableItems)
        .subscribe(_ => this._UpdateUI()));

    this.Disposables.Add(
      this.Property.Observe<ImagePickerItem<any>>(vm => vm.SelectedPickerItem)
        .subscribe(selected => this.SelectedItemChanged.next(selected.DataContext)));
  }

  public async AfterViewInit() : Promise<void>
  {
    this.Height = fromEvent(this.mContainer.nativeElement, 'resize')
      .pipe(map(_ => this.mContainer.nativeElement.offsetHeight));

  }

  private _UpdateUI()
  {
    this.mCdr.detectChanges()
  }

  private _UpdateItems(items: IImagePickerItemDataContext<any>[])
  {
    this.mItems = items.map<ImagePickerItem<any>>(dataContext =>
      new ImagePickerItem<any>(
        dataContext,
        dataContext.ImageSource,
        dataContext.MinImageSource,
        Command.Execute(async () => await this._SelectClicked(dataContext)))
    );

    if (this.ItemSource !== undefined && this.ItemSource.length > 0)
    {
      this.SelectedPickerItem = this.mItems[0];
      this.AvailableItems = this.mItems.filter(x => !x.Equals(this.SelectedPickerItem));
      this._UpdateNextAndPrevItems(this.SelectedPickerItem);
    }
  }

  private async _SelectClicked(clickedItem: IImagePickerItemDataContext<any>): Promise<void>
  {
    const item = this.AvailableItems.find(x => x.DataContext.Equals(clickedItem));
    const index = this.AvailableItems.indexOf(item);

    this.AvailableItems.splice(index, 1, this.SelectedPickerItem);
    this.SelectedPickerItem = item;

    this._UpdateNextAndPrevItems(item);

    this.mCdr.detectChanges();
  }

  private _UpdateNextAndPrevItems(clickedItem: ImagePickerItem<any>)
  {
    const selectedIndex = this.mItems.indexOf(clickedItem);

    if(selectedIndex + 1 >= this.mItems.length)
    {
      this.NextItem = this.mItems[0];
    }
    else
    {
      this.NextItem = this.mItems[selectedIndex + 1];
    }

    if(selectedIndex == 0)
    {
      this.PrevItem = this.mItems[this.mItems.length - 1];
    }
    else
    {
      this.PrevItem = this.mItems[selectedIndex - 1];
    }
  }
}

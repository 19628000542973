import { None } from '@webfacture/simplicitas-shared';
import { Observable } from 'rxjs';


export interface IFadeableComponent {

  StartHiding: Observable<None>;

  FadeTarget: HTMLElement;

}

export function IsFadeable(arg: any) : arg is IFadeableComponent
{
  return arg && arg.StartHiding && arg.StartHiding as Observable<None> !== undefined;
}

import { Component, ElementRef, ViewChild } from '@angular/core';
import { None } from '@webfacture/simplicitas-shared';
import { Animator, ShellAngularOverlayComponent } from '@webfacture/simplicitas-shell';
import { interval, Observable, timer } from 'rxjs';
import { delay, map, repeat, take } from 'rxjs/operators';
import { MailSendNotificationAnimator } from './MailSendNotificationAnimator';

@Component({
  selector: 'app-mail-send-notification',
  templateUrl: './mail-send-notification.component.html',
  styleUrls: ['./mail-send-notification.component.scss']
})
@Animator(new MailSendNotificationAnimator())
export class MailSendNotificationComponent extends ShellAngularOverlayComponent<MailSendNotificationComponent>
{

  private mImages: string[] = [
    '/assets/Startseite/DQDL_SS_Z2_21.svg',
    '/assets/Startseite/DQDL_SS_Z3_21.svg',
    '/assets/Startseite/DQDL_SS_Z4_21.svg',
    '/assets/Startseite/DQDL_SS_Z5_21.svg',
    '/assets/Startseite/DQDL_SS_Z6_21.svg',
  ];

  @ViewChild("loadingCanvas")
  public Canvas: ElementRef<HTMLCanvasElement>;

  public StartHiding : Observable<None>;

  constructor()
  {
    super();
  }

  public async Initialize(): Promise<void>
  {
    super.Initialize();

    this.StartHiding = timer(5000)
        .pipe(map(_ => new None()));

    this.Disposables.Add(
      this.StartHiding
        .pipe(delay(600))
        .subscribe(async _ => await this._HideOverlay())
      );
  }

  public async AfterViewInit(): Promise<void>
  {
    await super.AfterViewInit();

    const image = new Image();

    let scaleFactor : number= 1;

    if(document.body.offsetWidth < 600)
    {
      scaleFactor = 0.6;
    }

    const drawContext = this.Canvas.nativeElement.getContext("2d");
    const canvas = this.Canvas.nativeElement;

    image.onload = () =>
    {
      canvas.width = image.width * scaleFactor;
      canvas.height = image.height * scaleFactor;
      drawContext.clearRect(0, 0, canvas.width, canvas.height);
      drawContext.drawImage(image, 0, 0, image.width * scaleFactor, image.height * scaleFactor);
    };

    this.Disposables.Add(
      interval(175)
        .pipe(
          take(this.mImages.length),
          map(i => this.mImages[i]),
          repeat())
        .subscribe(src => image.src = src));
  }

  private async _HideOverlay() : Promise<void>
  {
    await this.OverlayContext.Hide();
  }

}

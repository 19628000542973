
import { Command } from "@webfacture/simplicitas-shell";
import { IEquatable } from '../../equality/IEquatable';


export class ImagePickerItem<T extends IEquatable<T>> implements IEquatable<ImagePickerItem<T>>
{
    DataContext: T;
    Source: string;
    MinSource: string;
    Click: Command;

    constructor(dataContext: T, imgSource: string, minImgSource: string, click: Command)
    {
        this.DataContext = dataContext;
        this.Source = imgSource;
        this.Click = click;
        this.MinSource = minImgSource ?? imgSource;
    }

    Equals(other: any): boolean 
    {
        if (other instanceof ImagePickerItem)
        {
            return this.DataContext.Equals(other.DataContext);
        }

        return false;
    }
}
import { Song } from "./Song";

export class AudioPlaylist
{
    public Songs: Song[];
    public Empty: boolean;

    private constructor(songs: Song[])
    {
        this.Songs = songs;
        this.Empty = this.Songs.length === 0;
    }

    public async InitializeSongData(audioPlayer: HTMLAudioElement) : Promise<void>
    {
      for (const song of this.Songs)
      {
        audioPlayer.src = song.Source;
        audioPlayer.load();
        song.Duration = audioPlayer.duration;
      }
    }

    public GetNextSong(current: Song) : Song
    {
        if(this.Empty)
            throw new Error("Playlist Empty");

        const currentIndex = this.Songs.indexOf(current);
        if(currentIndex + 1 >= this.Songs.length)
        {
            return this.Songs[0];
        }
        else
        {
            return this.Songs[currentIndex + 1];
        }
    }

    public GetPrevSong(current: Song) : Song
    {
        if(this.Empty)
            throw new Error("Playlist Empty");

        const currentIndex = this.Songs.indexOf(current);
        if(currentIndex - 1 < 0)
        {
            return this.Songs[this.Songs.length-1];
        }
        else
        {
            return this.Songs[currentIndex - 1];
        }
    }

    public static From(songPaths: Song[]) : AudioPlaylist
    {
        return new AudioPlaylist(songPaths);
    }

    public static Empty() : AudioPlaylist
    {
        return new AudioPlaylist([]);
    }
}

import { IScrollToPartExtender } from "./IScrollToPartExtender";
import { ScrollToPart } from "./ScrollToPart";
import { SiteId } from "./SiteId";
import { ScrollToStep } from "./ScrollToStep";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap, Power2 } from "gsap";
import { ConnectableObservable, fromEvent, interval, Observable, Subject, Subscription } from "rxjs";
import { throttle, publish, filter } from "rxjs/operators";
import { ScrollManager } from "src/app/util/DOM/ScrollManager";
import { DOMEventManager } from "src/app/util/DOM/DomEventManager";
import { SortedDictionary } from "@webfacture/simplicitas-shared";

export class ScrollToManager
{
    public SiteId: SiteId;
    public Parts: SortedDictionary<number, ScrollToPart>;

    public CurrentStepChanged: Observable<ScrollToStep>;
    public CurentStep: ScrollToStep;

    private mWheelObservable: ConnectableObservable<WheelEvent>;
    private mWheelSubscription: Subscription;
    private mCurrentStepSubject: Subject<ScrollToStep>;

    private mListenToWheelEvent: boolean;
    private mIgnoreSelectionUpdates: boolean;

    constructor(siteId: SiteId)
    {
        gsap.registerPlugin(ScrollToPlugin);
        this.SiteId = siteId;
        this.Parts = new SortedDictionary<number, ScrollToPart>((a, b) => a - b);
        this.CurentStep = null;

        this.mWheelObservable = publish<WheelEvent>()(
            fromEvent<WheelEvent>(document, 'wheel')
                .pipe(
                    throttle(() => interval(250)),
                    filter(_ => this.mListenToWheelEvent)));

        this.mWheelSubscription = this.mWheelObservable
            .subscribe(evt => this._MouseWheel(evt));

        DOMEventManager.SiteReloaded
            .pipe(filter(_ => this.CurentStep !== undefined))
            .subscribe(() => this.ScrollToStep(this.CurentStep));

        this.mCurrentStepSubject = new Subject();
        this.CurrentStepChanged = this.mCurrentStepSubject.asObservable();
    }

    public AddPart(index: number): IScrollToPartExtender
    {
        const scrollToPart = new ScrollToPart();
        this.Parts.SetValue(index, scrollToPart);
        return scrollToPart;
    }

    public ScrollToTop()
    {
        this.ScrollToStep(this.Parts.Values()[0].Steps[0]);
    }

    public ScrollToStep(step: ScrollToStep)
    {
        if (step == undefined)
            return;

        this.mWheelSubscription?.unsubscribe();
        this.CurentStep?.OnLeave();
        this.mCurrentStepSubject.next(step);
        this.mIgnoreSelectionUpdates = true;

        gsap.to(document.body,
            {
                scrollTo:
                {
                    y: step.Element,
                    autoKill: false,
                },
                duration: 1,
                ease: Power2.easeInOut,
                onComplete: () => 
                {
                    this.CurentStep = step;
                    this.mWheelSubscription = this.mWheelObservable.connect();
                    this.mIgnoreSelectionUpdates = false;
                }
            });
        step.OnEnter();
    }


    public UpdateCurrentStep(step: ScrollToStep)
    {
        if (this.mIgnoreSelectionUpdates)
            return;

        this.CurentStep = step;
        this.mCurrentStepSubject.next(step);
    }

    public GetFirstStepOfPart(partId: number): ScrollToStep
    {
        if (this.Parts.Keys().some(x => x === partId))
        {
            return this.Parts.GetValue(partId).Steps[0];
        }
        return undefined;
    }

    public StartListen()
    {
        ScrollManager.DisableScrolling();
        this.mListenToWheelEvent = true;
        this.mWheelSubscription = this.mWheelObservable.connect();
    }

    public StopListen()
    {
        ScrollManager.EnableScrolling();
        this.mListenToWheelEvent = false;
        this.mWheelSubscription?.unsubscribe();
    }

    private _MouseWheel(evt: WheelEvent)
    {
        if (evt.deltaY < 0)
        {
            const prevStep = this._GetPreviousStep(this.CurentStep);
            if (prevStep != undefined)
                this.ScrollToStep(prevStep);
        }
        else
        {
            const nextStep = this._GetNextStep(this.CurentStep);
            if (nextStep != undefined)
                this.ScrollToStep(nextStep);
        }
    }

    private _GetPreviousStep(currentElement: ScrollToStep): ScrollToStep
    {
        const stepList = this.Parts
            .Values()
            .flatMap(x => x.Steps);

        const currentIndex = stepList
            .indexOf(currentElement);

        if (currentIndex != 0)
        {
            return stepList[currentIndex - 1];
        }
        return undefined;
    }

    private _GetNextStep(currentElement: ScrollToStep): ScrollToStep
    {
        const stepList = this.Parts
            .Values()
            .flatMap(x => x.Steps);

        const currentIndex = stepList
            .indexOf(currentElement);

        if (currentIndex != stepList.length - 1)
        {
            return stepList[currentIndex + 1];
        }
        return undefined;
    }
}
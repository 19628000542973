import { Component } from '@angular/core';
import { ShellAngularComponent, Command, Animator } from '@webfacture/simplicitas-shell';
import { timer } from 'rxjs';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { ScrollToStep } from 'src/app/components/animation-manager/scroll-to/ScrollToStep';
import { AudioManagerService } from 'src/app/util/audio-manager/audio-manager.service';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { WellKnownHomeScrollToIds } from '../../WellKnownHomeScrollToIds';
import { BookPreviewAnimator } from './BookPreviewAnimator';
import { PdfLoadingService } from './services/pdf-loading.service';


@Component({
  selector: 'app-book-preview',
  templateUrl: './book-preview.component.html',
  styleUrls: ['./book-preview.component.scss']
})
@Animator(new BookPreviewAnimator())
export class BookPreviewComponent extends ShellAngularComponent<BookPreviewComponent>
{
  public PdfViewHeight: string = '75vh';
  public ZoomMode: string = 'page-fit';
  public BookSrc: string = "/assets/Texte/Quelle der Liebe Aufl-13 9.6.pdf";
  public PdfMode: string = "book";
  public CurrentPage: number = 1;
  public OrderBookCommand: Command;
  public NextPageCommand: Command;
  public PrevPageCommand: Command;
  public PdfLoadedCommand: Command;
  public SwitchPdfCommand: Command;
  public ScrollToManager: ScrollToManager;

  private mPageCount: number;
  private mPdfLoadingService: PdfLoadingService;

  constructor(
    scrollToManagerService: ScrollToManagerService,
    pdfLoadingService: PdfLoadingService)
  {
    super();
    this.ScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);
    this.mPdfLoadingService = pdfLoadingService;
  }

  public async Initialize(): Promise<void>
  {
    this.mPageCount = 25;

    this.Disposables
      .AddAction(() =>
      {
        (<any>window).PDFViewerApplication.unbindEvents();
        (<any>window).PDFViewerApplication.unbindWindowEvents();
        // (<any>window).PDFViewerApplication.cleanup();
        (<any>window).PDFViewerApplication.close();
      })

    this.BookSrc = window.location.origin + this.BookSrc;

    this.OrderBookCommand = Command.Execute(async () =>
    {
      await this._OpenInExternalUrl('https://www.wegweiser-verlag.at/shop/artikel_6171.php?v_sid=20210609ZjEcFhSQ');
    });

    this.NextPageCommand = Command.Execute(async () =>
    {
      if (this.CurrentPage + 1 > this.mPageCount)
      {
        this.CurrentPage = 1;
      }
      else
      {
        const scroll = document.body.scrollTop;
        this.CurrentPage++;
        document.body.scrollTop = scroll;
      }
    });

    this.PdfLoadedCommand = Command.Execute(async () =>
    {
      this.mPdfLoadingService.SetPdfLoaded();
    });

    this.PrevPageCommand = Command.Execute(async () =>
    {
      if (this.CurrentPage - 1 < 1)
      {
        this.CurrentPage = this.mPageCount;
      }
      else
      {
        this.CurrentPage--;
      }
    });

    const container = document.getElementById('book-preview-container');

    this.ScrollToManager
      .AddPart(WellKnownHomeScrollToIds.BookPreview)
      .AddStep(new ScrollToStep("Das Buch", container));

    if(document.body.clientWidth < 600)
    {
      this.BookSrc =  window.location.origin + "/assets/Texte/Quelle der Liebe Aufl-13 9.6_mobile.pdf";
      this.PdfViewHeight = '45vh';
    }

    this.SwitchPdfCommand = Command.Execute(async () =>
    {
      this.BookSrc = window.location.origin + '/assets/Texte/BW-Quelle der Liebe 1.3 Kopie.pdf';
      this.mPageCount = 2;
    })

    await super.Initialize();
  }

  public async AfterViewInit() : Promise<void>
  {
    this.Disposables
      .Add(timer(3500)
      .subscribe(_ =>
        {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }));
  }

  private async _OpenInExternalUrl(url: string)
  {
    window.open(url);
  }

}

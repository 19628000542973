import { Component, ElementRef, ViewChild } from '@angular/core';
import { Animator, ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { ArtReviewInfo } from './model/ArtReviewInfo';
import { ArtReviewDataProviderService } from './services/art-review-data-provider.service';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { WellKnownHomeScrollToIds } from './../../WellKnownHomeScrollToIds';
import { ScrollToStep } from 'src/app/components/animation-manager/scroll-to/ScrollToStep';
import { ArtReviewAnimator } from './ArtReviewAnimator';
import { fromEvent, combineLatest, Observable } from 'rxjs';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { ImgPickerComponent } from 'src/app/util/img-picker/img-picker.component';

@Component({
  selector: 'app-art-review',
  templateUrl: './art-review.component.html',
  styleUrls: ['./art-review.component.scss']
})
@Animator(new ArtReviewAnimator())
export class ArtReviewComponent extends ShellAngularComponent<ArtReviewComponent>
{
  public ScrollToManager: ScrollToManager;
  public ArtReviews: ArtReviewInfo[];
  public SelectedArt: ArtReviewInfo;

  @ViewChild('imgPicker')
  public ImagePicker: ImgPickerComponent;

  @ViewChild('imagePickerContainer')
  public ImagePickerContianer: ElementRef<HTMLElement>;

  @ViewChild('previewIamge')
  public PreviewImage: ElementRef<HTMLImageElement>;

  constructor(
    private mDataProvider: ArtReviewDataProviderService,
    scrollToManagerService: ScrollToManagerService)
  {
    super();
    this.ScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);
  }

  public async Initialize(): Promise<void>
  {
    const container = document.getElementById('art-review-part');

    this.ScrollToManager
      .AddPart(WellKnownHomeScrollToIds.ArtReview)
      .AddStep(new ScrollToStep("Gemälde", container));

    this.ArtReviews = await this.mDataProvider.GetArtReviews();

    const previewImages = document.getElementsByClassName('preview-img');

    const touchStart = fromEvent<TouchEvent>(previewImages, 'touchstart')
      .pipe(map(evt => evt.changedTouches[0].clientX));

    fromEvent<TouchEvent>(previewImages, 'touchend')
      .pipe(withLatestFrom(touchStart))
      .subscribe(evt => this._TouchMoved(evt[0], evt[1]))

    await super.Initialize();
  }

  public async AfterViewInit() : Promise<void>
  {
    const previewImage = document.getElementsByClassName('preview-img')[1] as HTMLElement;
    const textPart = document.getElementsByClassName('text-part')[1] as HTMLElement;

    const imageHeightObservable = new Observable<ResizeObserverEntry[]>(subscriber =>
      new ResizeObserver(entries => subscriber.next(entries))
      .observe(previewImage))
      .pipe(map(x => x[0].contentRect.height))
      .pipe(startWith(previewImage.offsetHeight));

    const textHeightObservable = new Observable<ResizeObserverEntry[]>(subscriber =>
      new ResizeObserver(entries => subscriber.next(entries))
      .observe(textPart))
      .pipe(map(x => x[0].contentRect.height))
      .pipe(startWith(textPart.offsetHeight));

    this.Disposables
      .Add(combineLatest([imageHeightObservable, textHeightObservable])
      .pipe(map((heights) => heights.reduce((a, b) => a+b)))
      .subscribe(height =>this.ImagePickerContianer.nativeElement.style.height = (height + 100) + 'px'));
  }

  private async _TouchMoved(evt: TouchEvent, startX: number): Promise<void>
  {
    if(evt.changedTouches[0].clientX - startX < - 100)
    {
      await this.ImagePicker.PrevItem.Click.Execute();
    }

    if(evt.changedTouches[0].clientX - startX > 100)
    {
      await this.ImagePicker.NextItem.Click.Execute();
    }
  }

  public SelectedItemChanged(selectedItem: any)
  {
    if (selectedItem instanceof ArtReviewInfo)
    {
      this.SelectedArt = selectedItem;
    }
  }
}

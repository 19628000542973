import { ViewDescription, ViewRoute } from "@webfacture/simplicitas-shell";
import { ImpressumComponent } from "./impressum.component";

@ViewDescription
export class ImpressumViewDescription
{
  public static ViewRoute: ViewRoute = new ViewRoute("Imperssum", ImpressumComponent);

  ViewRoute: ViewRoute = ImpressumViewDescription.ViewRoute;
}

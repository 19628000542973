import { Injectable } from '@angular/core';
import { None } from '@webfacture/simplicitas-shared';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfLoadingService 
{

  private mPdfLoadedSuject: Subject<None>;

  public get PdfLoaded() : Observable<None> {
    return this.mPdfLoadedSuject;
  }

  constructor() 
  { 
    this.mPdfLoadedSuject = new Subject<None>();
  }

  public SetPdfLoaded()
  {
    this.mPdfLoadedSuject.next(new None());
  }
}

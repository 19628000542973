import { Injectable } from '@angular/core';
import { ArtReviewInfo } from '../model/ArtReviewInfo';

@Injectable({
  providedIn: 'root'
})
export class ArtReviewDataProviderService
{
  constructor() { }

  public async GetArtReviews(): Promise<ArtReviewInfo[]>
  {
    return [
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_14_21.jpg',
        'Johannes schreibt die Offenbarung',
        'Der letzte überlebende Apostel bekommt die weitreichendsten Visionen. Die ' +
        'Zukunftsperspektiven reichen bis in unsere Zeit und darüber hinaus. Der Seher von Patmos, ' +
        'wie Johannes auch genannt wird, sah bereits unsere Zeit. Faszinierend!',
        '/assets/Startseite/min/DQDL_SS_14_21_prev.jpg'),
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_15_21.jpg',
        'Jesus trägt das Kreuz',
        'Der Herrscher des Weltalls erniedrigt sich freiwillig auf unsere Ebene. Aber nicht nur, dass ' +
        'der Sohn Gottes Mensch wird. Er ist auch noch bereit für seine Geschöpfe am Kreuz zu sterben. ' +
        'Man kann es einfach nicht fassen, wie groß seine Liebe zu uns Menschen ist.',
        '/assets/Startseite/min/DQDL_SS_15_21_prev.jpg'),
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_21_21.jpg',
        'Die Wiederkunft',
        'Als sich der Heiland mit einer enormen Anzahl von Engeln dem Planeten Erde nähert, erblickt der Apostel ' +
        'Johannes in einer Vision dieses prachtvolle Schauspiel. Er sieht die himmlische Schar wie ein Reiterheer ' +
        'auf weißen Pferden auf sich zukommen. Wohl dem, der von den Engeln mitgenommen wird, um dem Untergang auf ' +
        'dieser Erde zu entfliehen.',
        '/assets/Startseite/min/DQDL_SS_21_21_prev.jpg'),
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_17_21.jpg',
        'Der Gute Hirte',
        'Jesus sorgt für uns wie ein guter Hirte für seine Schafe. König David beschreibt es in Psalm 23 eindrucksvoll ' +
        'zu der Zeit, als er als Junge noch Schafhirte war. „Der Herr ist mein Hirte. Mir wird nichts mangeln. Er weidet ' +
        'mich auf einer grünen Aue und führt mich zum frischen Wasser.“',
        '/assets/Startseite/min/DQDL_SS_17_21_prev.jpg'),
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_18_21.jpg',
        'Der Blindgeborene',
        'Jesus schenkt einem Blindgeborenen das Augenlicht. Der Geheilte ist nun überglücklich. Das erregt aber den Neid ' +
        'der Führer des Landes Israel. Sie versuchen alles, um das geschehene Heilungswunder in Zweifel zu ziehen. In ' +
        'eifersüchtiger Weise wollen sie nicht wahrhaben, dass Jesus alle Kennzeichen des angekündigten Messias aufweist.',
        '/assets/Startseite/min/DQDL_SS_18_21_prev.jpg'),
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_19_21.jpg',
        'Die Himmelsleiter',
        'Als Jakob auf der Flucht vor seinem Zwillingsbruder Esau auf freier Welt die Nacht verbringt, träumt er etwas '+
        'Außergewöhnliches. Eine Leiter ragt von der Erde bis zum Himmel. Darauf steigen die Engel auf und nieder. Beruhigt ' +
        'über den Schutz Gottes setzt Jakob seine Flucht fort. Nun weiß er, wie nahe ihm Gott ist.',
        '/assets/Startseite/min/DQDL_SS_19_21_prev.jpg'),
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_20_21.jpg',
        'Der Weinstock',
        'Es ist das letzte Gleichnis Jesu auf dem Weg nach Gethsemane in der Nacht seiner Gefangennahme. Wie die Rebe mit ' +
        'dem Weinstock so ist der Nachfolger Jesu mit seinem Herrn inniglich verbunden und bekommt dadurch Kraft über Kraft.',
        '/assets/Startseite/min/DQDL_SS_20_21_prev.jpg'),
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_16_21.jpg',
        'Der Mittler',
        'Zwischen dem Schöpfergott und seinen Geschöpfen hat sich seit dem Sündenfall eine tiefe Kluft gebildet. Es ist ' +
        'mangelndes Vertrauen von uns Menschen in unseren himmlischen Vater. Doch Jesus Christus, der Sohn Gottes, wurde ' +
        'Mensch, um diese Kluft durch seinen Tod am Kreuz zu überwinden. Er ist der einzige Mittler zwischen Himmel und Erde. ' +
        'Er sagt von sich: Ich bin der Weg, die Wahrheit und das Leben. Niemand kommt zum Vater, außer durch mich. (Johannes 14,6)',
        '/assets/Startseite/min/DQDL_SS_16_21_prev.jpg'),
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_22_21.png',
        'Das Lamm',
        'Als Johannes der Täufer am Ufer des Jordan predigt und die Leute zur Umkehr ruft, nähert sich Jesus der Schar der Zuhörer. ' +
        'Johannes erblickt den Messias und ruft: "Siehe, das ist Gottes Lamm, welches der Welt Sünde trägt." Jesus ist symbolisch ' +
        'das unschuldige Lamm, das für die Sünden der Menschen am Kreuz stirbt.',
        '/assets/Startseite/min/DQDL_SS_22_21_prev.png'),
      new ArtReviewInfo(
        '/assets/Startseite/min/DQDL_SS_23_21.jpg',
        'Das Sternentor',
        'Es gibt nur zwei Wege: Einen breiten Weg, der sich als Sackgasse entpuppt und auf dem die Masse unterwegs ist. Der andere ' +
        'ist ein schmaler Weg, der zum Sternentor führt. Es ist das Tor ins Universum. Die Eintrittskarte dafür ist Liebe und ' +
        'Vertrauen zum Schöpfer. Nur dadurch sind wir in der Lage, Gottes Richtlinien mit Freude zu befolgen.',
        '/assets/Startseite/min/DQDL_SS_23_21_prev.jpg'),
    ];
  }
}

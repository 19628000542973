<div id="dotNavContainer">
    <ul class="dotNavList">
        <li (click)="item.Click.Execute();" *ngFor="let item of Items" id="nav-item-{{item.Id}}" class="dotNavItem">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.62 276.62">
                <defs>
                    <style>
                        .unselected {
                            fill: #6D757E;
                        }
                    </style>
                </defs>
                <g data-name="Ebene 2">  
                    <circle id="nav-icon-{{item.Id}}" class="unselected" cx="138.31" cy="138.31" r="138.31" />
                </g>
            </svg>
        </li>
    </ul>
</div>
import { Command, MediaQueryAnimator } from '@webfacture/simplicitas-shell';
import { MediaPlayerComponent } from './media-player.component';
import { gsap, Power2 } from 'gsap/gsap-core';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { DisposableBag } from '@webfacture/simplicitas-shared/dist/Disposables/DisposableBag';

export class MediaPlayerAnimator extends MediaQueryAnimator<MediaPlayerComponent>
{

    private mDisposables: DisposableBag;

    InitializeAnimations(): void
    {
      this.mDisposables = new DisposableBag();

      const showMediaPlayer = gsap.timeline({ paused: true });
      const hideMediaPlayer = gsap.timeline();
      const hideMessageBox = gsap.timeline();
      const showMessageBox = gsap.timeline({ paused: true });

      hideMediaPlayer.to(this.ViewModel.MediaPlayerBox.nativeElement,
          {
              display: 'none',
              opacity: 0,
              duration: 0.3
          }, 0)
          .to(this.ViewModel.ShowMediaPlayerButton.nativeElement,
          {
              display: 'inline',
              opacity: 1,
              duration: 0.3,
              ease: Power2.easeIn
          });

      hideMessageBox.to(this.ViewModel.MediaPlayerInfoBox.nativeElement,
          {
              display: 'none',
              opacity: 0,
              duration: 0.1
          }, 0);

      showMessageBox
          .from(this.ViewModel.MediaPlayerInfoBox.nativeElement,
              {
                  x: "+=100",
              })
          .to(this.ViewModel.MediaPlayerInfoBox.nativeElement,
          {
              display: 'flex',
              opacity: 1,
              duration: 0.5
          }, 0);

      this.mMediaQueries
        .SetValue(0, Command.Execute(
          () => this._InitializeMobile(
            showMediaPlayer,
            hideMediaPlayer,
            showMessageBox,
            hideMessageBox)));

      this.mMediaQueries
        .SetValue(600, Command.Execute(
            () => this._InitializeGreaterMobile(
              showMediaPlayer,
              hideMediaPlayer,
              showMessageBox,
              hideMessageBox)));

      if(window.innerWidth > 600)
      {
          this._InitializeGreaterMobile(
            showMediaPlayer,
            hideMediaPlayer,
            showMessageBox,
            hideMessageBox);
      }
      else
      {
          this._InitializeMobile(
            showMediaPlayer,
            hideMediaPlayer,
            showMessageBox,
            hideMessageBox);
      }
    }

    private async _InitializeMobile(
      showMediaPlayer: gsap.core.Timeline,
      hideMediaPlayer: gsap.core.Timeline,
      showMessageBox: gsap.core.Timeline,
      hideMessageBox: gsap.core.Timeline): Promise<void>
    {
      showMediaPlayer.to(this.ViewModel.MediaPlayerBox.nativeElement,
        {
            display: 'flex',
            opacity: 1,
            duration: 0.3,
            ease: Power2.easeIn
        },0)
        .to(this.ViewModel.ShowMediaPlayerButton.nativeElement,
        {
            display: 'none',
            opacity: 0,
            duration: 0.3
        });

      merge(
        fromEvent(this.ViewModel.ExitButton.nativeElement, 'click'))
          .subscribe(_ =>
            hideMediaPlayer.restart());

      fromEvent(this.ViewModel.ShowMediaPlayerButton.nativeElement, 'click')
          .subscribe(_ =>
              {
                  showMediaPlayer.restart()

                  if(this.ViewModel.MediaPlayerInfoBox.nativeElement.style.display === 'flex')
                    hideMessageBox.restart();
              });

      this.ViewModel.ShowMessageBox
          .pipe(filter(_ => this.ViewModel.MediaPlayerBox.nativeElement.style.display === 'none'))
          .subscribe(isVisible =>
              {
                  if(isVisible)
                    showMessageBox.restart();
                  else
                    hideMessageBox.restart();
              });
    }

    private async _InitializeGreaterMobile(
      showMediaPlayer: gsap.core.Timeline,
      hideMediaPlayer: gsap.core.Timeline,
      showMessageBox: gsap.core.Timeline,
      hideMessageBox: gsap.core.Timeline): Promise<void>
    {
      showMediaPlayer.to(this.ViewModel.MediaPlayerBox.nativeElement,
        {
            display: 'inline',
            opacity: 1,
            duration: 0.3,
            ease: Power2.easeIn
        },0)
        .to(this.ViewModel.ShowMediaPlayerButton.nativeElement,
        {
            display: 'none',
            opacity: 0,
            duration: 0.3
        });

     fromEvent(this.ViewModel.MediaPlayerBox.nativeElement, 'mouseleave')
          .pipe(filter(_ => this.ViewModel.MediaPlayerBox.nativeElement.style.display !== 'none'))
          .subscribe(_ =>
            hideMediaPlayer.restart());

      const hoverObservable = merge(
          fromEvent(this.ViewModel.ShowMediaPlayerButton.nativeElement, 'mouseover')
              .pipe(map(_ => true)),
          fromEvent(this.ViewModel.ShowMediaPlayerButton.nativeElement, 'mouseleave')
              .pipe(map(_ => false)));

      hoverObservable
      .pipe(debounceTime(200))
          .subscribe(shouldShow =>
              {
                  if(shouldShow)
                    showMediaPlayer.restart()

                  if(this.ViewModel.MediaPlayerInfoBox.nativeElement.style.display === 'flex')
                    hideMessageBox.restart();
              });

      this.ViewModel.ShowMessageBox
          .pipe(filter(_ => this.ViewModel.MediaPlayerBox.nativeElement.style.display === 'none'))
          .subscribe(isVisible =>
              {
                  if(isVisible)
                    showMessageBox.restart();
                  else
                    hideMessageBox.restart();
              });
    }

    Dispose()
    {
      this.mDisposables.Dispose();
    }

}

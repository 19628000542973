import { IScrollToPartExtender } from "./IScrollToPartExtender";
import { ScrollToStep } from "./ScrollToStep";


export class ScrollToPart implements IScrollToPartExtender
{
    public Steps : ScrollToStep[];

    constructor()
    {
        this.Steps = [];
    }

    AddStep(scrollToStep: ScrollToStep): IScrollToPartExtender 
    {
        this.Steps.push(scrollToStep);

        return this;
    }
}
import { Component } from '@angular/core';
import { Command, ShellAngularComponent, ShellRoutingService, OverlayService } from '@webfacture/simplicitas-shell';
import { ImpressumViewDescription } from '../../../impressum/ImpressumViewDescription';
import { DatenschutzViewDescription } from '../../../datenschutz/DatenschutzViewDescription';
import { ScrollToManagerService } from '../../../components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from '../../../components/animation-manager/scroll-to/ScrollToManager';
import { WellKnownSiteIds } from '../../../WellKnownSiteIds';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends ShellAngularComponent<FooterComponent>
{

  private mOverlayService: OverlayService;

  public ImpressumCommand : Command;
  public DatenschutzCommand: Command;

  constructor(routingService: OverlayService)
  {
    super();

    this.mOverlayService = routingService;
  }

  public async Initialize() : Promise<void>
  {
    this.ImpressumCommand = Command.Execute(async () =>
    {
      await this.mOverlayService.Show(ImpressumViewDescription.ViewRoute);
    });

    this.DatenschutzCommand = Command.Execute(async () =>
    {
      await this.mOverlayService.Show(DatenschutzViewDescription.ViewRoute);
    });
  }

}

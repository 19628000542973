import { Component, OnInit } from '@angular/core';
import { Animator, MenuItemDataContext, ShellHeaderComponent } from '@webfacture/simplicitas-shell';
import { IMenuAnimator } from './IMenuAnimator';
import { MenuAnimator } from './MenuAnimator';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
@Animator(new MenuAnimator())
export class HeaderComponent extends ShellHeaderComponent<HeaderComponent> implements OnInit {

  private mBurgerButton: HTMLElement;
  public Logo: HTMLElement;
  public Menu: HTMLElement;
  private mMenuOpen: boolean;

  private mMenuAnimator: IMenuAnimator;

  public MenuItems: MenuItemDataContext[] = [];

  public async Initialize() : Promise<void>
  {
    this.mBurgerButton = document.getElementById('burger-button');
    this.Logo = document.getElementById('logo');
    this.Menu = document.getElementById('menu');

    await super.Initialize();

    this.mMenuOpen = false;

    this.mMenuAnimator = this.Animator as unknown as IMenuAnimator;
  }

  ngAfterViewChecked(): void 
  {
    if(this.mMenuAnimator !== undefined && !this.mMenuAnimator.Initialized)
    {
      this.Animator.InitializeAnimations();
    }
  }

  public async ToggleMenu() : Promise<void>
  {
    this.mMenuOpen = !this.mMenuOpen;
    if(this.mMenuOpen)
    {
      await this.ShowMenu();
    }
    else
    {
      await this.HideMenu();
    }
  }

  public async ShowMenu() : Promise<void>
  {
    if(this.mMenuAnimator.IsMobile)
    {
      this.mBurgerButton.classList.add('hamburger-slim-active');
      this.Menu.classList.add('menu-active');
      this.Logo.classList.add('logo-active');
      this.mMenuAnimator.ShowMenu();
      this.mMenuOpen = true;
    }
  }

  public async HideMenu() : Promise<void>
  {
    if(this.mMenuAnimator.IsMobile)
    {
      this.mBurgerButton.classList.remove('hamburger-slim-active');
      await this.mMenuAnimator.HideMenu();
      this.Menu.classList.remove('menu-active');
      this.Logo.classList.remove('logo-active');
      
      this.mMenuOpen = false;
    }
  }

  UpdateData(menutItems: MenuItemDataContext[]) 
  {
    this.MenuItems = menutItems;
  }

}

import { Component } from '@angular/core';
import { None } from '@webfacture/simplicitas-shared';
import { Animator, ShellAngularOverlayComponent } from '@webfacture/simplicitas-shell';
import { fromEvent, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { FadeAnimator } from '../util/animator/FadeAnimator';
import { IFadeableComponent } from '../util/animator/IFadeableComponent';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
@Animator(new FadeAnimator<ImpressumComponent>(0.4,0.6))
export class ImpressumComponent extends ShellAngularOverlayComponent<ImpressumComponent> implements IFadeableComponent
{
  public StartHiding: Observable<None>;
  public FadeTarget: HTMLElement;

  public async Initialize() : Promise<void>
  {

    this.FadeTarget = document.getElementById('impressum-container');
    const closeButton = document.getElementById('impressumCloseButton');

    this.StartHiding = fromEvent(closeButton, 'click')
      .pipe(map(_ => new None()));

    this.Disposables
      .Add(
        this.StartHiding
          .pipe(delay(700))
          .subscribe(async () => await this.OverlayContext.Hide()));
  }
}

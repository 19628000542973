import { ChangeDetectorRef, Component } from '@angular/core';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { DotNavItemDataContext } from './items/DotNavItemDataContext';
import { DotNavDataProvider } from './provider/dot-nav-data-provider.service';
import tippy from 'tippy.js';
import { Observable, Subject } from 'rxjs';
import { Animator, ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { DotNavSelectionChangedAnimator } from './DotNavSelectionChangedAnimator';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-dot-nav',
  templateUrl: './dot-nav.component.html',
  styleUrls: ['./dot-nav.component.scss']
})
@Animator(new DotNavSelectionChangedAnimator())
export class DotNavComponent extends ShellAngularComponent<DotNavComponent>
{
  public Items : DotNavItemDataContext[];
  public Selected: Observable<DotNavItemDataContext>;

  private mSelectedSubject: Subject<DotNavItemDataContext>;

  constructor(private mDataProvider: DotNavDataProvider,
    private mChangeDetectionRef: ChangeDetectorRef) 
    {
      super();
      this.mSelectedSubject = new Subject();
      this.Selected = this.mSelectedSubject.asObservable();
    }

    

  async Initialize(): Promise<void> 
  {
    await super.Initialize();

    this.mDataProvider
      .SelectedChanged
      .subscribe(x => this._TriggerSelectionUpdate(x));
  }

  async AfterViewInit(): Promise<void> 
  {
    await super.AfterViewInit();
    
    this.Items = this.mDataProvider.GetDotNavItems(WellKnownSiteIds.Home);
    this.mChangeDetectionRef.detectChanges();
    
    for (const item of this.Items) 
    {
      const element = document.getElementById('nav-item-'+item.Id.toString());
      tippy(element, 
      {
        content: item.Name,
        animation: 'fade',
        placement: 'left',
      });
    }
  }

  private _TriggerSelectionUpdate(id: Guid)
  {
    const item = this.Items.find(x => x.Id.equals(id));

    if(item != undefined)
    {
      this.mSelectedSubject.next(item);
    }
  }
}

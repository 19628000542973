import { Component } from '@angular/core';
import { Animator, ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { AudioManagerService } from '../../../util/audio-manager/audio-manager.service';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { ScrollToStep } from 'src/app/components/animation-manager/scroll-to/ScrollToStep';
import { LandingSectionAnimator } from './LandingSectionAnimator';
import { WellKnownHomeScrollToIds } from './../../WellKnownHomeScrollToIds';

@Component({
  selector: 'app-landing-section',
  templateUrl: './landing-section.component.html',
  styleUrls: ['./landing-section.component.scss']
})
@Animator(new LandingSectionAnimator())
export class LandingSectionComponent extends ShellAngularComponent<LandingSectionComponent> {

  public ScrollToManager: ScrollToManager;

  constructor(
    private mAudioManager: AudioManagerService,
    scrollToManagerService: ScrollToManagerService) 
  {
    super();

    this.ScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);
  }

  async Initialize(): Promise<void>
  {
    const landingSectionContainer = document.getElementById('landing-section-container');

    this.ScrollToManager
      .AddPart(WellKnownHomeScrollToIds.LandingSection)
      .AddStep(new ScrollToStep("Home", landingSectionContainer));

    await super.Initialize();
  }

  async PlayMusic(): Promise<void>
  {
    this.mAudioManager.StartPlay();
  }

  async PauseMusic(): Promise<void>
  {
    this.mAudioManager.StopPlay();
  }

  async RestartOrPrevSong(): Promise<void>
  {
    if (this.mAudioManager.IsInStaringPart())
    {
      this.mAudioManager.PrevSong();
    }
    else
    {
      this.mAudioManager.RestartSong();
    }
  }

  async NextSong(): Promise<void>
  {
    this.mAudioManager.NextSong();
  }

}


<div id="mail-send-container" class="mail-send-container">

  <img class="img-rose" src="/assets/Startseite/min/DQDL_SS_33_21.png" alt="Rose">


  <div class="text-container">

    <h1>Danke für Ihre Nachricht</h1>

    <h2>Ihre Nachricht wird sobald als möglich bearbeitet.</h2>

  </div>

  <div class="text-forward">
    <canvas #loadingCanvas id="forward-loading-canvas">

    </canvas>
  </div>

</div>

import { Component } from '@angular/core';
import { ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { timer } from 'rxjs';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends ShellAngularComponent<HomeComponent>
{

  private mScrollToManager: ScrollToManager;

  constructor(scrollToManagerService: ScrollToManagerService)
  {
    super();

    this.mScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);
  }


  async AfterViewInit(): Promise<void>
  {
    await super.AfterViewInit();

    this.Disposables.Add(
      timer(1000)
      .subscribe(_ => this.mScrollToManager.ScrollToTop()));

  }
}

<app-dot-nav id="dot-nav"></app-dot-nav>

<app-media-player id="media-player"></app-media-player>

<app-water-drop-effect [QuerySelector]="'#shell-window-content'" [Resolution]="4096" [Multiplier]="1" id="water-drop-effect"></app-water-drop-effect>

<app-landing-section id="landing-section"></app-landing-section>

<app-think-about id="think-about"></app-think-about>

<app-book-preview id="book-preview"></app-book-preview>

<app-art-review id="art-review"></app-art-review>

<app-storys id="storys"></app-storys>

<app-references id="references"></app-references>

<app-repografie id="repografie"></app-repografie>

<app-the-artist id="the-artist"></app-the-artist>

<app-contact id="contact"></app-contact>

<app-footer id="footer"></app-footer>

<div id="media-player-container">
  <img #showMediaPlayerButton class="music-hover-button" src="/assets/Startseite/DQDL_SS_Z1_21.svg">



  <div #infoBox class="media-player-info-box">
    <h3>Spiele hier einen Song ab!</h3>
  </div>

  <div #mediaPlayerBox class="media-player-box">

    <img #exitButton src="/assets/Startseite/times-solid.svg" class="media-player-exit-cross">

    <div class="media-player-song-info">
        <span>{{ (CurrentSong | async)?.Interpret ?? 'Unkown' }}</span>
        <h3>{{ (CurrentSong | async)?.Name ?? 'Unkown'}}</h3>
    </div>

    <div class="media-player-controls">

        <img #prevButton (click)="PrevCommand.Execute()" class="media-player-controls-item" src="/assets/Startseite/DQDL_SS_2_white_21.svg">
        <img #playButton (click)="PlayCommand.Execute()" class="media-player-controls-item" src="/assets/Startseite/DQDL_SS_3_white_21.svg">
        <img #nextButton (click)="NextCommand.Execute()" class="media-player-controls-item" src="/assets/Startseite/DQDL_SS_5_white_21.svg">
    </div>

    <div #progessBar class="media-player-progress">
        <div #progessVisual id="progress-bar" class="media-player-progress-bar"></div>
        <div class="media-player-progress__time">
            <span class="media-player-progress__time-item" id="media-player-current-time">{{ CurrentTime | async }}</span>
            <span class="media-player-progress__time-item" id="media-player-duration-time">{{ Duration | async }}</span>
        </div>
    </div>

  </div>
</div>

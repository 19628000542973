import { Command, MediaQueryAnimator } from "@webfacture/simplicitas-shell";
import { HeaderComponent } from "./header.component";
import { IMenuAnimator } from "./IMenuAnimator";
import { TimelineMax, gsap, Power2 } from 'gsap';

export class MenuAnimator extends MediaQueryAnimator<HeaderComponent> implements IMenuAnimator
{
    public Initialized: boolean;
    public IsMobile: boolean;

    private mShowTimeline: TimelineMax;

    public InitializeAnimations(): void 
    {
        this.mMediaQueries.SetValue(0, Command.Execute(() => this._InitializeMobileMenu()));
        this.mMediaQueries.SetValue(600, Command.Execute(() => this._InitializeGreaterMobile()));

        const menuEntries = document.getElementsByClassName('menuEntry');
        if(menuEntries.length > 0)
        {
            this.Initialized = true;
        }

        if(window.innerWidth > 600)
        {
            this._InitializeGreaterMobile();
        }
        else
        {
            this._InitializeMobileMenu();
        }
    }

    private async _InitializeGreaterMobile() : Promise<void>
    {
        this.IsMobile = false;
        const menuEntries = document.getElementsByClassName('menuEntry');
        [].slice.call(menuEntries).forEach((menuEntry) => 
        {
            gsap.to(menuEntry, 
                {
                    opacity: 1
                });
        });
    }

    private async _InitializeMobileMenu() : Promise<void>
    {
        this.IsMobile = true;
        this.mShowTimeline = new TimelineMax();

        this.mShowTimeline.to(this.ViewModel.Menu,
        {
            startAt: {
                opacity: 0
            },
            opacity: 1,
            duration: 0.25
        }, "0");

        this.mShowTimeline.to(this.ViewModel.Logo,
        {
            startAt: {
                opacity: 0
            },
            opacity: 1,
            duration: 0.25
        }, "0");
        const menuEntries = document.getElementsByClassName('menuEntry');

        [].slice.call(menuEntries).forEach((menuEntry, i) => 
        {
            if(i % 2 == 0)
            {
                this.mShowTimeline.from(menuEntry,
                    {
                        x: -window.innerWidth,
                        opacity: 0,
                        duration: 0.6,
                        ease: Power2.easeInOut
                    },
                    "0");
            }
            else
            {
                this.mShowTimeline.from(menuEntry,
                    {
                        x: window.innerWidth,
                        opacity: 0,
                        duration: 0.6,
                        ease: Power2.easeInOut
                    },
                    "0");
            }
        });
    }

    public Dispose() : void
    {
        this.mShowTimeline?.kill();
    }

    public async ShowMenu(): Promise<void> 
    {
        this.mShowTimeline.restart();
    }
    
    public async HideMenu(): Promise<void> 
    {
        await this.mShowTimeline.reverse(1).then();
    }
}
import { Component, OnInit } from '@angular/core';
import { ShellAngularComponent, Command, Animator } from '@webfacture/simplicitas-shell';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { ScrollToStep } from 'src/app/components/animation-manager/scroll-to/ScrollToStep';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { WellKnownHomeScrollToIds } from '../../WellKnownHomeScrollToIds';
import { ReferencesAnimator } from './ReferencesAnimator';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
@Animator(new ReferencesAnimator())
export class ReferencesComponent extends ShellAngularComponent<ReferencesComponent>
{
  public OpenChristReferences: Command;
  public OpenYoutubeLink: Command;

  public ScrollToManager: ScrollToManager;

  constructor(scrollToManagerService: ScrollToManagerService) 
  {
    super();

    this.ScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);
  }


  public async Initialize(): Promise<void>
  {
    this.OpenChristReferences = Command.Execute(async () => this._OpenLinkInNewTab('https://www.christlicheressourcen.com'));
    this.OpenYoutubeLink = Command.Execute(async () => this._OpenLinkInNewTab('https://www.youtube.com/watch?v=oNRqaVi5K0c'));

    const container = document.getElementById('references-section');

    this.ScrollToManager
      .AddPart(WellKnownHomeScrollToIds.References)
      .AddStep(new ScrollToStep("Referenzen", container));

    const referenceElement2 = document.getElementById('reference-img-2') as HTMLImageElement;

    if(document.body.clientWidth < 600)
    {
      referenceElement2.src = 'assets/Startseite/DQDL_SS_26_01_21.jpg';
    }

    await super.Initialize();
  }

  private _OpenLinkInNewTab(url: string)
  {
    window.open(url);
  }
}


import { TimelineMax } from "gsap";
import { Guid } from "guid-typescript";

export class ScrollToStep
{
    public Id: Guid;
    public Element: HTMLElement;
    public Name: string;
    
    private mAnimation: TimelineMax;
    private mCustomInAnimation: TimelineMax;
    private mCustomOutAnimation: TimelineMax;

    constructor(
        name: string, 
        element: HTMLElement, 
        animation?: TimelineMax,
        customInAnimaiton?: TimelineMax,
        customOutAnimation?: TimelineMax)
    {
        this.Id = Guid.create();
        this.Element = element;
        this.Name = name;
        this.mAnimation = animation;
        this.mCustomInAnimation = customInAnimaiton;
        this.mCustomOutAnimation = customOutAnimation;
    }

    public OnEnter() : void
    {
        this.mAnimation?.play();
        this.mCustomInAnimation?.restart();
    }

    public OnLeave() : void
    {
        this.mAnimation?.reverse(this.mAnimation?.duration()/2);
        this.mCustomOutAnimation?.restart();
    }
}
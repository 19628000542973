import { Component, OnInit } from '@angular/core';
import { CookieVerificationService, ShellAngularOverlayComponent } from '@webfacture/simplicitas-shell';

@Component({
  selector: 'app-cookie-check',
  templateUrl: './cookie-check.component.html',
  styleUrls: ['./cookie-check.component.scss']
})
export class CookieCheckComponent extends ShellAngularOverlayComponent<CookieCheckComponent>  
{

  constructor(private mCookieVerificationService: CookieVerificationService) 
  {
    super();
  }

  public async Hide() : Promise<void>
  {
    this.mCookieVerificationService.SetUsageVerified();
    this.mCookieVerificationService.StartCheck();

    await this.OverlayContext.Hide();
  } 

}

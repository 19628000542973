import { ShellAnimator } from '@webfacture/simplicitas-shell';
import { MailSendNotificationComponent } from './mail-send-notification.component';
import { gsap, Linear } from 'gsap';

export class MailSendNotificationAnimator extends ShellAnimator<MailSendNotificationComponent>
{
  InitializeAnimations(): void
  {
    const container = document.getElementById('mail-send-container');

    gsap
      .fromTo(container,
      {
        opacity: 0
      },
      {
        opacity: 1,
        ease: Linear.easeInOut,
        duration: 0.3
      })

    const tl = gsap.timeline(
        {
            paused: true,
            defaults: {duration: 1}
        }
    )
    .to(container,
        {
            opacity: 0,
            ease: Linear.easeInOut
        });

    this.ViewModel.StartHiding
        .subscribe(_ => tl.restart());
  }

}

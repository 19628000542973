import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { DotNavDataProvider } from 'src/app/components/dot-nav/provider/dot-nav-data-provider.service';

@Injectable({
  providedIn: 'root'
})
export class WaterDropRefresherService implements OnDestroy
{
  public CustomRefresh: Observable<boolean>;

  private mCustomRefreshSubject: Subject<boolean>;

  private mDotNavSeletionChanged: Subscription;

  constructor(dotNavDataProvider: DotNavDataProvider) 
  {
    this.mCustomRefreshSubject = new Subject<boolean>();
    this.CustomRefresh = this.mCustomRefreshSubject;
    this.mDotNavSeletionChanged = dotNavDataProvider.SelectedChanged
      .subscribe(_ => this.TriggerRefresh());
  }


  public TriggerRefresh()
  {
    this.mCustomRefreshSubject.next(true);
  }

  ngOnDestroy(): void
  {
    this.mDotNavSeletionChanged?.unsubscribe();
  }
}



<div id="landing-section-container">

    <div class="left-part">

        <img class="music-control" src="/assets/Startseite/min/DQDL_SS_1_21.png" alt="Rose">

    </div>

    <div class="right-part">

        <div class="text-container">
            <h1>Die Quelle<br>der Liebe</h1>
            <p>Wen dürstet der komme...</p>
            <!-- <div class="music-container">
                <p>Music:</p>
                <img (click)="RestartOrPrevSong();" class="music-control" src="/assets/Startseite/DQDL_SS_2_21.png" alt="Previous">
                <img (click)="PlayMusic();" class="music-control" src="/assets/Startseite/DQDL_SS_3_21.png" alt="Play">
                <img (click)="PauseMusic();" class="music-control" src="/assets/Startseite/DQDL_SS_4_21.png" alt="Pause">
                <img (click)="NextSong();" class="music-control" src="/assets/Startseite/DQDL_SS_5_21.png" alt="Next">
            </div> -->
        </div>

    </div>
</div>
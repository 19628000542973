import { gsap } from 'gsap';
import { ShellAnimator } from '@webfacture/simplicitas-shell';
import { LoadingViewComponent } from './loading-view.component';


export class LoadingViewAnmator extends ShellAnimator<LoadingViewComponent>
{
    InitializeAnimations(): void 
    {
        const container = document.getElementById('loading-container');

        const tl = gsap.timeline(
            {
                paused: true,
                defaults: {duration: 0.6}
            }
        )
        .to(container, 
            {
                opacity: 0
            });

        this.ViewModel.LoadingFinished
            .subscribe(_ => tl.restart());
    }
    
}
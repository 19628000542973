import { Component, ElementRef, ViewChild } from '@angular/core';
import { Animator, Command, ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { AudioManagerService } from 'src/app/util/audio-manager/audio-manager.service';
import { Observable, fromEvent, combineLatest, merge, interval, timer, concat } from 'rxjs';
import { Song } from 'src/app/util/audio-manager/Song';
import { debounceTime, delay, filter, map, repeat, repeatWhen, startWith, withLatestFrom } from 'rxjs/operators';
import { None } from '@webfacture/simplicitas-shared';
import { MediaPlayerAnimator } from './MediaPlayerAnimator';

@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss']
})
@Animator(new MediaPlayerAnimator())
export class MediaPlayerComponent extends ShellAngularComponent<MediaPlayerComponent>
{
  @ViewChild('showMediaPlayerButton')
  public ShowMediaPlayerButton: ElementRef<HTMLElement>;

  @ViewChild('infoBox')
  public MediaPlayerInfoBox: ElementRef<HTMLElement>;

  @ViewChild('exitButton')
  public ExitButton: ElementRef<HTMLElement>;

  @ViewChild('playButton')
  public PlayButton: ElementRef<HTMLImageElement>;
  public PlayCommand: Command;

  @ViewChild('prevButton')
  public PrevButton: ElementRef<HTMLImageElement>;
  public PrevCommand: Command;

  @ViewChild('nextButton')
  public NextButton: ElementRef<HTMLImageElement>;
  public NextCommand: Command;

  @ViewChild('mediaPlayerBox')
  public MediaPlayerBox: ElementRef<HTMLElement>;

  @ViewChild('progessBar')
  public ProgessBar: ElementRef<HTMLElement>;

  @ViewChild('progessVisual')
  public ProgessVisual: ElementRef<HTMLElement>;

  public CurrentSong: Observable<Song>;
  public Duration: Observable<string>;
  public CurrentTime: Observable<string>;
  public ShowMessageBox: Observable<boolean>;

  private mPlayButtonResource = "/assets/Startseite/DQDL_SS_3_white_21.svg";
  private mPauseButtonResource = "/assets/Startseite/DQDL_SS_4_white_21.svg";
  private mAudioManager: AudioManagerService;

  constructor(audioManager: AudioManagerService)
  {
    super();
    this.mAudioManager = audioManager;
  }

  public async Initialize() : Promise<void>
  {
    this.PlayCommand = Command.Execute(async () => this._PlayOrPauseMusic());
    this.PrevCommand = Command.Execute(async () => this._RestartOrPrevSong());
    this.NextCommand = Command.Execute(async () => this._NextSong());

    this.CurrentSong = this.mAudioManager.CurrentSong;

    this.Duration = this.mAudioManager.SongDuration
      .pipe(map(
        songDuration => this._ConvertToFormattedTime(songDuration)));

    this.CurrentTime = this.mAudioManager
      .MusicProgress
      .pipe(map(duration => this._ConvertToFormattedTime(duration)))
      .pipe(startWith('00:00'));

    this.Disposables.Add
      combineLatest([this.mAudioManager.MusicProgress, this.mAudioManager.SongDuration])
        .subscribe(x => this._UpdateProgress(x[0], x[1]));
  }

  public async AfterViewInit() : Promise<void>
  {
    const progressBarClick = fromEvent<MouseEvent>(this.ProgessBar.nativeElement, 'click');

    this.Disposables.Add(
      progressBarClick
        .pipe(withLatestFrom(this.mAudioManager.SongDuration))
        .subscribe((x) => this._SetProgress(x[1], x[0])));

    const showMessageBoxObservable =
      merge(
        this.mAudioManager
          .IsPlaying,
        interval(60000)
          .pipe(withLatestFrom(this.mAudioManager
            .IsPlaying, (_, isPlaying) => isPlaying)))
      .pipe(debounceTime(15000))
      .pipe(filter(x => !x))
      .pipe(map(isPlaying => !isPlaying));

    const hideMessageBoxObservable = showMessageBoxObservable
      .pipe(delay(15000))
      .pipe(map(_ => false));

    this.Disposables.Add(
      this.mAudioManager
        .IsPlaying
        .subscribe(isPlaying => this._UpdateMusicControls(isPlaying)))

    this.ShowMessageBox = merge(showMessageBoxObservable, hideMessageBoxObservable);
  }

  private _ConvertToFormattedTime(duration: number) : string
  {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.round(duration % 60);
    return this._StrPadLeft(minutes,'0',2) + ':' + this._StrPadLeft(seconds,'0',2);
  }

  private _StrPadLeft(text : any, pad : string, length: number)
  {
    return (new Array(length+1).join(pad)+text).slice(-length);
  }

  private _UpdateMusicControls(isPlaying: boolean) : void
  {
    this.PlayButton.nativeElement.src = isPlaying
      ? this.mPauseButtonResource
      : this.mPlayButtonResource;
  }

  private async _PlayOrPauseMusic(): Promise<void>
  {
    if(this.PlayButton.nativeElement.src.includes(this.mPlayButtonResource))
    {
      this.mAudioManager.StartPlay();
    }
    else
    {
      this.mAudioManager.StopPlay();
    }
  }

  private async _RestartOrPrevSong(): Promise<void>
  {
    if (this.mAudioManager.IsInStaringPart())
    {
      this.mAudioManager.PrevSong();
    }
    else
    {
      this.mAudioManager.RestartSong();
    }
  }

  private async _NextSong(): Promise<void>
  {
    this.mAudioManager.NextSong();
  }

  private _UpdateProgress(currentTime: number, duration: number)
  {
    const progressPercent = (currentTime / duration) * 100;
    this.ProgessVisual.nativeElement.style.width = `${progressPercent}%`;
  }

  private async _SetProgress(duration: number, evt: MouseEvent) : Promise<None>
  {
      const width = this.ProgessBar.nativeElement.clientWidth;
      const setPoint = evt.offsetX;
      const time = (setPoint / width) * duration;

      this.mAudioManager.SetTime(time);

      return new None();
  }
}

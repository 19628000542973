import { Component } from '@angular/core';
import { Animator, Command, ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { ScrollToStep } from 'src/app/components/animation-manager/scroll-to/ScrollToStep';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { WellKnownHomeScrollToIds } from '../../WellKnownHomeScrollToIds';
import { RepografieAnimator } from './RepografieAnimator';

@Component({
  selector: 'app-repografie',
  templateUrl: './repografie.component.html',
  styleUrls: ['./repografie.component.scss']
})
@Animator(new RepografieAnimator())
export class RepografieComponent extends ShellAngularComponent<RepografieComponent> {

  public OrderCommand: Command;
  public OpenUrlCommand: Command;

  public ScrollToManager: ScrollToManager;

  constructor(scrollToManagerService: ScrollToManagerService) 
  {
    super();

    this.ScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);
  }

  async Initialize(): Promise<void> 
  {
    this.OrderCommand = Command.Execute(
      async () => this._OpenExternalUrlInNewTab('http://www.maestro-shop.fineartprint.de'));

    this.OpenUrlCommand = Command.Execute(
      async () => this._OpenExternalUrlInNewTab('http://www.maestro-shop.fineartprint.de'));

    const container = document.getElementById('repografie-section');

    this.ScrollToManager
      .AddPart(WellKnownHomeScrollToIds.Repografie)
      .AddStep(new ScrollToStep("Repografie", container));

    await super.Initialize();
  }

  private async _OpenExternalUrlInNewTab(url: string): Promise<void>
  {
    window.open(url, '_blank');
  }

}



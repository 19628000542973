import { ViewDescription, ViewRoute } from "@webfacture/simplicitas-shell";
import { DatenschutzComponent } from './datenschutz.component';

@ViewDescription
export class DatenschutzViewDescription
{
  public static ViewRoute: ViewRoute = new ViewRoute("Datenschutz", DatenschutzComponent);

  ViewRoute: ViewRoute = DatenschutzViewDescription.ViewRoute;
}

import { Component, Injector } from '@angular/core';
import { AngularShellApplication, HeaderData, HeaderDataProviderService, ShellRoutingService, MenuItemFactory, Command, CookieVerificationService, OverlayService, WellKnownRegions } from '@webfacture/simplicitas-shell';
import { HomeViewDescription } from './home/HomeViewDescription';
import { HeaderComponent } from './header/header.component';
import { AudioManagerService } from './util/audio-manager/audio-manager.service';
import { AudioPlaylist } from './util/audio-manager/AudioPlaylist';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { WellKnownHomeScrollToIds } from './home/WellKnownHomeScrollToIds';
import { CookieCheckViewDescription } from './components/cookie-check/CookieCheckViewDescription';
import { LoadingViewViewDescription } from './loading-view/LoadingViewViewDescription';
import { MobileMediaSessionService } from './util/mobile-media-session/mobile-media-session.service';
import { Song } from './util/audio-manager/Song';
import { MailSendNotificationViewDescription } from './home/components/contact/mail-send-notification/MailSendNotificationViewDescription';
import { ImpressumViewDescription } from './impressum/ImpressumViewDescription';
import { DatenschutzViewDescription } from './datenschutz/DatenschutzViewDescription';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AngularShellApplication
{
  title = 'Die Quelle der Liebe';

  private mHomeScrollToManager: ScrollToManager;

  constructor(
    injector: Injector,
    private mAudioManager: AudioManagerService,
    private mShellRoutingService: ShellRoutingService,
    private mHeaderDataProvider: HeaderDataProviderService,
    scrollToManagerService: ScrollToManagerService,
    private mOverlayService: OverlayService,
    private mCookieVerification: CookieVerificationService,
    _: MobileMediaSessionService)
  {
    super(injector);
    this.mHeaderDataProvider.RegisterHeaderData(new HeaderData(HeaderComponent));

    pdfDefaultOptions.assetsFolder = 'bleeding-edge';

    this.mHomeScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);

    this.mCookieVerification
      .CookieVerificationRequiered
      .subscribe(async _ => await this._ShowCookieVerification());
  }

  public async Initialize(): Promise<void>
  {
    let lastHeight = window.innerHeight;
    window.addEventListener('resize', () =>
    {
      if(window.innerHeight >= lastHeight)
      {
        lastHeight = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${lastHeight * 0.01}px`);
      }
    });

    await this.mAudioManager.ConfigurePlaylist(AudioPlaylist.From([
      new Song("/assets/Music/2 Wenn Mein Auge Schaut Den Heiland.mp3", "Wenn Mein Auge Schaut Den Heiland", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/3 Du Großer Gott.mp3", "Du Großer Gott", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/4 Herr, Deine Liebe.mp3", "Herr, Deine Liebe", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/5 Schönster Herr Jesu.mp3", "Schönster Herr Jesu", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/6 Turn Your Eyes Upon Jesus.mp3", "Turn Your Eyes Upon Jesus", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/7-Take-Time-To-Be-Holy.mp3", "Take Time To Be Holy", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/8-Heilig_-Heilig_-Heilig.mp3", "Heilig Heilig Heilig", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/9-For-You-I-Am-Praying.mp3", "For You I Am Praying", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/10-O-Bleibe_-Herr.mp3", "O Bleibe Herr", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/11-Für-Mich-Gingst-Du-Nach-Golgatha.mp3", "Für Mich Gingst Du Nach Golgatha", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/12-Erlöst-Aus-Gnaden.mp3", "Erlöst Aus Gnaden", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/13-We-Gather-Together.mp3", "We Gather Together", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
      new Song("/assets/Music/14-The-Holy-City.mp3", "The Holy Cit", "MMMag. Carmen Piesslinger\nMMag. Rahel Piesslinger"),
    ]));

    await super.Initialize();
  }

  async _ShowCookieVerification()
  {
    this.mCookieVerification.StopCheck();
    await this.mOverlayService.Show(CookieCheckViewDescription.ViewRoute);
  }

  async AfterViewInit(): Promise<void>
  {
    this.mCookieVerification.StartCheck();

    this.mHeaderDataProvider.SetMenuData(
      [
        MenuItemFactory.Child('Home').WithCommand(Command.Execute(async () => this._ScrollToPart(WellKnownHomeScrollToIds.LandingSection))),
        MenuItemFactory.Child('Buch').WithCommand(Command.Execute(async () => this._ScrollToPart(WellKnownHomeScrollToIds.BookPreview))),
        MenuItemFactory.Child('Gemälde').WithCommand(Command.Execute(async () => this._ScrollToPart(WellKnownHomeScrollToIds.ArtReview))),
        MenuItemFactory.Child('Storys').WithCommand(Command.Execute(async () => this._ScrollToPart(WellKnownHomeScrollToIds.Storys))),
        MenuItemFactory.Child('Künstler').WithCommand(Command.Execute(async () => this._ScrollToPart(WellKnownHomeScrollToIds.TheArtist))),
        MenuItemFactory.Child('Kontakt').WithCommand(Command.Execute(async () => this._ScrollToPart(WellKnownHomeScrollToIds.Contact))),
        MenuItemFactory.Child('Lan').WithCommand(Command.Execute(async () => { })),
      ]
    );

    await this.mShellRoutingService.Navigate(HomeViewDescription.ViewRoute);
    await this.mOverlayService.Show(LoadingViewViewDescription.ViewRoute);

    await super.AfterViewInit();
  }


  private async _ScrollToPart(partId: number) : Promise<void>
  {
    if(this.mShellRoutingService.mActiveRoutes.getValue(WellKnownRegions.MainContent) !== HomeViewDescription.ViewRoute)
    {
      const navigationTask = await this.mShellRoutingService.Navigate(HomeViewDescription.ViewRoute);
      await navigationTask.ComponentDisplayed;
    }

    const part = this.mHomeScrollToManager.GetFirstStepOfPart(partId);
    this.mHomeScrollToManager.ScrollToStep(part);
  }

}

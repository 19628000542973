import { ShellAngularComponent, ShellAnimator } from "@webfacture/simplicitas-shell";
import { IsFadeable } from './IFadeableComponent';
import { gsap, Linear } from 'gsap';

export class FadeAnimator<T extends ShellAngularComponent<T>> extends ShellAnimator<T>
{

  private mFadeInDuration: number;
  private mFadeOutDuration: number;

  constructor(fadeInDuration: number = 0.3, fadeOutDuration: number = 0.3)
  {
    super();
    this.mFadeInDuration = fadeInDuration;
    this.mFadeOutDuration = fadeOutDuration;
  }

  InitializeAnimations(): void
  {
    if(!IsFadeable(this.ViewModel))
    {
      throw new Error("In order to use the FadeAnimator, the component needs to implement the IFadeableComponent Interface");
    }

    const fadeTarget = this.ViewModel.FadeTarget;

    gsap
      .fromTo(fadeTarget,
      {
        opacity: 0
      },
      {
        opacity: 1,
        ease: Linear.easeInOut,
        duration: this.mFadeInDuration
      })

    const tl = gsap.timeline(
        {
            paused: true,
            defaults: {duration: this.mFadeOutDuration}
        }
    )
    .to(fadeTarget,
        {
            opacity: 0,
            ease: Linear.easeInOut
        });

    this.ViewModel.StartHiding
        .subscribe(_ => tl.restart());
  }

}

import { NgModule } from '@angular/core';
import { SimplicitasShellModule } from '@webfacture/simplicitas-shell'
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingSectionComponent } from './home/components/landing-section/landing-section.component';
import { ArtReviewComponent } from './home/components/art-review/art-review.component';
import { MaintenanceViewComponent } from './maintenance-view/maintenance-view.component';
import { WorkInProgessComponent } from './maintenance-view/components/work-in-progess/work-in-progess.component';
import { MatButtonModule } from '@angular/material/button';
import { ReferencesComponent } from './home/components/references/references.component';
import { BookPreviewComponent } from './home/components/book-preview/book-preview.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactComponent } from './home/components/contact/contact.component';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { HttpClientModule } from '@angular/common/http';
import { ThinkAboutComponent } from './home/components/think-about/think-about.component';
import { FooterComponent } from './home/components/footer/footer.component';
import { TheArtistComponent } from './home/components/the-artist/the-artist.component';
import { Error404Component } from './error404/error404.component';
import { ImgPickerComponent } from './util/img-picker/img-picker.component';
import { RepografieComponent } from './home/components/repografie/repografie.component';
import { StorysComponent } from './home/components/storys/storys.component';
import { DotNavComponent } from './components/dot-nav/dot-nav.component';
import { WaterDropEffectComponent } from './home/effects/water-drop-effect/water-drop-effect.component';
import { CookieCheckComponent } from './components/cookie-check/cookie-check.component';
import { LoadingViewComponent } from './loading-view/loading-view.component';
import { MediaPlayerComponent } from './components/media-player/media-player.component';
import { MailSendNotificationComponent } from './home/components/contact/mail-send-notification/mail-send-notification.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LandingSectionComponent,
    ArtReviewComponent,
    MaintenanceViewComponent,
    WorkInProgessComponent,
    ReferencesComponent,
    BookPreviewComponent,
    ContactComponent,
    ThinkAboutComponent,
    FooterComponent,
    TheArtistComponent,
    Error404Component,
    ImgPickerComponent,
    RepografieComponent,
    StorysComponent,
    DotNavComponent,
    WaterDropEffectComponent,
    CookieCheckComponent,
    LoadingViewComponent,
    MediaPlayerComponent,
    MailSendNotificationComponent,
    ImpressumComponent,
    DatenschutzComponent,
  ],
  imports: [
    BrowserModule,
    SimplicitasShellModule,
    BrowserAnimationsModule,
    NgxExtendedPdfViewerModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: '6LdNtCgbAAAAAH0sphRqqUqrgJg9wxmTzxtER-Hw',
        badge: 'bottomleft',
        type: 'image',
        theme: 'light',
      },
      language: 'en',
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

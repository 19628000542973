import { ViewDescription, ViewRoute } from "@webfacture/simplicitas-shell";
import { MailSendNotificationComponent } from './mail-send-notification.component';


@ViewDescription
export class MailSendNotificationViewDescription
{
  public static ViewRoute: ViewRoute = new ViewRoute("MailSendNotification", MailSendNotificationComponent);

  ViewRoute: ViewRoute = MailSendNotificationViewDescription.ViewRoute;
}

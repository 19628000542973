<div id="art-review-container">

    <div class="parallax safari_only">

    </div>

    <div #imagePickerContainer id="art-review-part" class="lower-part">

        <ng-template #dataTemplate>
            <img id="art-review-preview-img" class="preview-img" src="{{ SelectedArt?.ImageSource }}" alt="">

            <div class="text-part">
                <h1 class="description-header">Gemälde-Beschreibung</h1>
                <h2 class="art-name">{{ SelectedArt?.PaintingName }}</h2>
                <p>{{ SelectedArt?.PaintingDescription}}</p>
            </div>

        </ng-template>

        <app-img-picker #imgPicker class="image-picker-component" (SelectedItemChanged)="SelectedItemChanged($event)" [ItemSource]="ArtReviews" [DataItemTemplate]="dataTemplate"></app-img-picker>
    </div>

    <div class="mobile-parallax">

    </div>
</div>

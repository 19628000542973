
<div id="chookie-check-container">

    <p class="info-text">
        Der Schutz deiner Daten ist uns wichtig.<br>
        Wir verwenden Cookies und ähnliche Technologien, um dir auf unserer Webseite ein optimales Erlebnis zu ermöglichen.<br>
        <a class="link"> Privacy Policy </a>
    </p>

    <div class="button-container">
        <button mat-raised-button class="buttondesign" (click)="Hide();" color="primary">OK</button>
    </div>
</div>
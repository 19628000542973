import { Injectable } from '@angular/core';
import { Command } from '@webfacture/simplicitas-shell';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToStep } from 'src/app/components/animation-manager/scroll-to/ScrollToStep';
import { SiteId } from 'src/app/components/animation-manager/scroll-to/SiteId';
import { DotNavItemDataContext } from '../items/DotNavItemDataContext';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DotNavDataProvider 
{
  public SelectedChanged: Observable<Guid>;

  constructor(private mScrollToManagerService: ScrollToManagerService) 
  {
    gsap.registerPlugin(ScrollToPlugin);

    this.SelectedChanged = mScrollToManagerService
      .GetScrollToManager(WellKnownSiteIds.Home)
      .CurrentStepChanged
      .pipe(map((x) => x.Id));
  }

  public GetDotNavItems(siteId: SiteId): DotNavItemDataContext[]
  {
    const scrollToManager = this.mScrollToManagerService.GetScrollToManager(siteId);

    return scrollToManager.Parts
      .Values()
      .flatMap(x => x.Steps)
      .map(x => this._ConvertToItemDataContext(x, scrollToManager));
  }

  private _ConvertToItemDataContext(step: ScrollToStep, scrollToManager: ScrollToManager): DotNavItemDataContext
  {
    const command = Command.Execute(async () => scrollToManager.ScrollToStep(step));
    return new DotNavItemDataContext(step.Id, step.Name, command);
  }
}

import { ViewDescription, ViewRoute } from "@webfacture/simplicitas-shell";
import { CookieCheckComponent } from '../../components/cookie-check/cookie-check.component';


@ViewDescription
export class CookieCheckViewDescription
{
    public static ViewRoute: ViewRoute = new ViewRoute("CookieCheck", CookieCheckComponent, false);

    ViewRoute: ViewRoute = CookieCheckViewDescription.ViewRoute;
}
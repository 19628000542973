import { Component } from '@angular/core';
import { None } from '@webfacture/simplicitas-shared';
import { Animator, HeaderControlService, ShellAngularOverlayComponent, CookieVerificationService } from '@webfacture/simplicitas-shell';
import { forkJoin, interval, Observable, timer, race, NEVER } from 'rxjs';
import { catchError, delay, map, repeat, take, timeout } from 'rxjs/operators';
import { ArtReviewDataProviderService } from '../home/components/art-review/services/art-review-data-provider.service';
import { LoadingViewAnmator } from './LoadingViewAnimator';
import { PdfLoadingService } from '../home/components/book-preview/services/pdf-loading.service';
import { AudioManagerService } from '../util/audio-manager/audio-manager.service';

@Component({
  selector: 'app-loading-view',
  templateUrl: './loading-view.component.html',
  styleUrls: ['./loading-view.component.scss']
})
@Animator(new LoadingViewAnmator())
export class LoadingViewComponent extends ShellAngularOverlayComponent<LoadingViewComponent>
{
  public LoadingImg: string = '';
  public LoadingFinished: Observable<None>;

  private mImages: string[] = [
    '/assets/Startseite/DQDL_SS_Z2_21.svg',
    '/assets/Startseite/DQDL_SS_Z3_21.svg',
    '/assets/Startseite/DQDL_SS_Z4_21.svg',
    '/assets/Startseite/DQDL_SS_Z5_21.svg',
    '/assets/Startseite/DQDL_SS_Z6_21.svg',
  ];

  private mHeaderControlService: HeaderControlService;
  private mArtReviewDataProvider: ArtReviewDataProviderService
  private mCookieVerification: CookieVerificationService
  private mPdfLoadingService: PdfLoadingService;
  private mAudioManagerService: AudioManagerService;

  constructor(
    headerControlService: HeaderControlService,
    artReviewDataProvider: ArtReviewDataProviderService,
    cookieVerification: CookieVerificationService,
    pdfLoadingService: PdfLoadingService,
    audioManager: AudioManagerService)
  {
    super();
    this.mHeaderControlService = headerControlService;
    this.mArtReviewDataProvider = artReviewDataProvider;
    this.mCookieVerification = cookieVerification;
    this.mPdfLoadingService = pdfLoadingService;
    this.mAudioManagerService = audioManager;

    this.mCookieVerification.StopCheck();
  }

  public async Initialize() : Promise<void>
  {
    this.mHeaderControlService.SetHeaderVisiblity(false);

    const canvas = document.getElementById('loading-canvas') as HTMLCanvasElement;
    const drawContext = canvas.getContext("2d");

    const image = new Image();
    let scaleFactor : number= 1;

    if(document.body.offsetWidth < 600)
    {
      scaleFactor = 0.6;
    }

    image.onload = () =>
    {
      canvas.width = image.width * scaleFactor;
      canvas.height = image.height * scaleFactor;
      drawContext.clearRect(0, 0, canvas.width, canvas.height);
      drawContext.drawImage(image, 0, 0, image.width * scaleFactor, image.height * scaleFactor);
    };

    this.Disposables.Add(
      interval(175)
        .pipe(
          take(this.mImages.length),
          map(i => this.mImages[i]),
          repeat())
        .subscribe(src => image.src = src));

    const artReviews = await this.mArtReviewDataProvider
        .GetArtReviews();

    const preloader = this.ImagePreloader
        .Builder()
        .AddAssets(artReviews.map(x => x.ImageSource))
        .AddAsset('assets/Startseite/min/DQDL_SS_1_21.png')
        .AddAsset('assets/Startseite/min/DQDL_SS_33_21.png')
        .AddAsset('assets/Startseite/min/DQDL_SS_24_21.jpg')
        .AddAsset('assets/Startseite/min/DQDL_SS_26_21.jpg')
        .AddAsset('assets/Startseite/min/DQDL_SS_28_21.jpg')
        .AddAsset('assets/Startseite/min/DQDL_SS_29_21.jpg')
        .AddAsset('assets/Startseite/min/DQDL_SS_32_21.png')
        .Build();

    const minimumTime = timer(2000).pipe(take(1));
    const pdfLoaded = this.mPdfLoadingService
      .PdfLoaded
      .pipe(take(1));

    const audioInfoLoaded = this.mAudioManagerService
      .SongsLoaded
      .pipe(take(1));

    this.LoadingFinished = race(
      forkJoin([preloader.LoadingFinished, minimumTime, pdfLoaded, audioInfoLoaded]),
      NEVER.pipe(
        timeout(5000),
        catchError(err =>
          {
            console.log("Error during Pre-Initialzation: "+ err);
            return [1];
          })));

    this.Disposables.Add(this.LoadingFinished
      .subscribe(async (_) => await this._NavigateToHome()));

    this.Disposables.Add(this.LoadingFinished
      .pipe(delay(600))
      .subscribe(async (_) => await this.OverlayContext.Hide()));

    await preloader.StartLoading();
  }

  private async _NavigateToHome(): Promise<void>
  {
    this.mHeaderControlService.SetHeaderVisiblity(true);
    this.mCookieVerification.StartCheck();
  }
}






export const WellKnownHomeScrollToIds =
{
    LandingSection: 1,
    ThinkAbout: 2,
    BookPreview: 3,
    ArtReview: 4,
    Storys: 5,
    References: 6,
    Repografie: 7,
    TheArtist: 8,
    Contact: 9
};
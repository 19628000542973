import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Animator, OverlayService, ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { ReCaptchaService } from 'angular-recaptcha3';
import { timer } from 'rxjs';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { ScrollToStep } from 'src/app/components/animation-manager/scroll-to/ScrollToStep';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { WellKnownHomeScrollToIds } from '../../WellKnownHomeScrollToIds';
import { ContactAnimator } from './ContactAnimator';
import { MailSendNotificationViewDescription } from './mail-send-notification/MailSendNotificationViewDescription';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Animator(new ContactAnimator())
export class ContactComponent extends ShellAngularComponent<ContactComponent> {

  public SiteKey: '6LdNtCgbAAAAAH0sphRqqUqrgJg9wxmTzxtER-Hw';
  public SecretKey: '';

  public aFormGroup: FormGroup;

  @ViewChild(FormGroupDirective)
  private mFormDirective: FormGroupDirective;

  public Email = new FormControl('', [Validators.required, Validators.email]);
  public ForName = new FormControl('', [Validators.required]);
  public SurName = new FormControl('', [Validators.required]);
  public Message = new FormControl('');

  public ScrollToManager: ScrollToManager;
  private mOverlayService: OverlayService;

  constructor(
    private mFormBuilder: FormBuilder,
    private recaptchaService: ReCaptchaService,
    private mHttpClient: HttpClient,
    scrollToManagerService: ScrollToManagerService,
    overlayService: OverlayService)
  {
    super();

    this.mOverlayService = overlayService;
    this.ScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);
  }

  async Initialize(): Promise<void>
  {
    this.aFormGroup = this.mFormBuilder.group({
      Email: this.Email,
      ForName: this.ForName,
      SurName: this.SurName,
      Message: this.Message
    });

    const container = document.getElementById('contact-container');

    this.ScrollToManager
      .AddPart(WellKnownHomeScrollToIds.Contact)
      .AddStep(new ScrollToStep("Kontakt", container));

    await super.Initialize();
  }

  async AfterViewInit(): Promise<void>
  {
    await super.AfterViewInit();
  }

  public async OnSubmit(): Promise<void>
  {


    const token = await this.recaptchaService.execute({ action: 'login' });
    const recaptchaValid = await this._IsRecaptchaSuccessful(token);

    if (!this.aFormGroup.invalid && recaptchaValid)
    {
      const data = {
        name: this.ForName.value + ' ' + this.SurName.value,
        email: this.Email.value,
        message: this.Message.value
      };

      const response = await this.mHttpClient.post("/.netlify/functions/contact-mail", JSON.stringify(data)).toPromise();
      if(response.hasOwnProperty('content'))
      {
        await this.mOverlayService.Show(MailSendNotificationViewDescription.ViewRoute);

        this.Disposables.Add(
          timer(500)
            .subscribe(_ => {
              this.mFormDirective.resetForm();
              this.aFormGroup.markAsPristine();
              this.aFormGroup.markAsUntouched();
            }));
      }
    }
  }

  private async _IsRecaptchaSuccessful(token: string): Promise<boolean>
  {
    try
    {
      const response = await this.mHttpClient.post("/.netlify/functions/recaptcha", JSON.stringify({ token: token })).toPromise();
      if (response.hasOwnProperty('success'))
      {
        return response['success'];
      }
      return false;
    }
    catch (e)
    {
      console.log(e);
      return false;
    }
  }

  getErrorMessage()
  {
    if (this.Email.hasError('required'))
    {
      return '';
    }

    return this.Email.hasError('email') ? 'Ungültige E-Mail Adresse' : '';
  }

}

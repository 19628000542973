import { Command } from "@webfacture/simplicitas-shell";
import { Guid } from "guid-typescript";


export class DotNavItemDataContext
{
    public Id: Guid;
    public Name: string;
    public Click: Command;

    constructor(id: Guid, name: string, command: Command)
    {
        this.Id = id;
        this.Name = name;
        this.Click = command;
    }
}
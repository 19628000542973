import { Injectable, OnDestroy } from '@angular/core';
import { DisposableBase } from '@webfacture/simplicitas-shared';
import { AudioManagerService } from '../audio-manager/audio-manager.service';
import { Song } from '../audio-manager/Song';


let specialNavigator: any;

@Injectable({
  providedIn: 'root'
})
export class MobileMediaSessionService extends DisposableBase implements OnDestroy {

  private mAudioManager: AudioManagerService;

  constructor(audioManager: AudioManagerService)
  {
    super();

    this.mAudioManager = audioManager;
    specialNavigator = navigator;

    if('mediaSession' in specialNavigator)
    {
      this.Disposables.Add(
        audioManager
          .CurrentSong
          .subscribe(currentSong => this._SetupMediaMetadata(currentSong)));
    }
  }

  private _SetupMediaMetadata(currentSong: Song)
  {
    // @ts-ignore
    specialNavigator.mediaSession.metadata = new MediaMetadata({
      title: currentSong.Name,
      artist: currentSong.Interpret
    });

    specialNavigator.mediaSession.setActionHandler('previoustrack', () =>
    {
      this.mAudioManager.PrevSong();
    });

    specialNavigator.mediaSession.setActionHandler('nexttrack', () =>
    {
      this.mAudioManager.NextSong();
    });
  }

  ngOnDestroy(): void
  {
    this.Dispose();
  }


}

import { Component } from '@angular/core';
import { Animator, ShellAngularComponent } from '@webfacture/simplicitas-shell';
import { ScrollToManagerService } from 'src/app/components/animation-manager/scroll-to-manager.service';
import { ScrollToManager } from 'src/app/components/animation-manager/scroll-to/ScrollToManager';
import { ScrollToStep } from 'src/app/components/animation-manager/scroll-to/ScrollToStep';
import { WellKnownSiteIds } from 'src/app/WellKnownSiteIds';
import { WellKnownHomeScrollToIds } from './../../WellKnownHomeScrollToIds';
import { ThinkAboutAnimator } from './ThinkAboutAnimator';
import { WaterDropRefresherService } from '../../effects/water-drop-effect/water-drop-refresher.service';

@Component({
  selector: 'app-think-about',
  templateUrl: './think-about.component.html',
  styleUrls: ['./think-about.component.scss']
})
@Animator(new ThinkAboutAnimator())
export class ThinkAboutComponent extends ShellAngularComponent<ThinkAboutComponent>
{
  public ScrollToManager: ScrollToManager;

  private mAdditionalPart: HTMLElement;
  public buttontext: string;

  constructor(
    private mEffectRefreshService: WaterDropRefresherService,
    scrollToManagerService: ScrollToManagerService) 
  {
    super();

    this.ScrollToManager = scrollToManagerService.GetScrollToManager(WellKnownSiteIds.Home);
  }

  public async Initialize(): Promise<void>
  {
    this.mAdditionalPart = document.getElementById('additionalpart');
    this.buttontext = 'Weiter lesen';

    const thinkAboutContainer = document.getElementById('thinkabout-section');

    this.ScrollToManager
      .AddPart(WellKnownHomeScrollToIds.ThinkAbout)
      .AddStep(new ScrollToStep("Zum Nachdenken", thinkAboutContainer));

    await super.Initialize();
  }

  ToggleAdditionalPart()
  {
    const isactive = this.mAdditionalPart.classList.toggle('hidden-part');

    if (isactive)
    {
      this.buttontext = 'Weiter lesen';
    }
    else
    {
      this.buttontext = 'Weniger lesen';
    }

    this.mEffectRefreshService.TriggerRefresh();
  }
}

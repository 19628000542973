

export class Song
{
    public Source: string;
    public Name: string;
    public Interpret: string;
    public Duration: number;

    constructor(source: string, name? :string, interpret?: string)
    {
        this.Source = source;
        this.Name = name ?? 'Unknown';
        this.Interpret = interpret ?? 'Unknown';
    }

    public async LoadSong(musicPlayer: HTMLAudioElement) : Promise<void>
    {
        musicPlayer.src = this.Source;
        musicPlayer.load();
        this.Duration = musicPlayer.duration;
    }
}


<div class="footer-containter">

    <img class="logo" src="../../assets/Startseite/DQDL_SS_32_21.png" alt="Gray-Rose" srcset="">
    <p class="webfacture-text">Webfacture E.U.</p>

    <div class="right-part">
        <a id="links-header">Links:</a>
        <a class="active-link" (click)="ImpressumCommand.Execute()">Impressum</a>
        <a class="active-link" (click)="DatenschutzCommand.Execute()">Datenschutzerklärung</a>
    </div>

</div>

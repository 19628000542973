
import { MediaQueryAnimator } from '@webfacture/simplicitas-shell';
import { WellKnownHomeScrollToIds } from '../../WellKnownHomeScrollToIds';
import { BookPreviewComponent } from './book-preview.component';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

export class BookPreviewAnimator extends MediaQueryAnimator<BookPreviewComponent>
{
    Dispose()
    {

    }

    InitializeAnimations(): void
    {
        gsap.registerPlugin(ScrollTrigger);

        if (window.innerWidth > 800)
        {
            this._InitializeDesktop();
        }
    }

    private _InitializeDesktop()
    {
        const container = document.getElementById('book-preview-container');

        const scrollToStep = this.ViewModel.ScrollToManager.GetFirstStepOfPart(WellKnownHomeScrollToIds.BookPreview);

        gsap.to(document.body,
            {
                scrollTrigger: {
                    end: "bottom 50%",
                    trigger: container,
                    start: "top 50%",
                    onEnter: () => 
                    {
                        this.ViewModel.ScrollToManager.UpdateCurrentStep(scrollToStep);
                    },
                    onEnterBack: () => 
                    {
                        this.ViewModel.ScrollToManager.UpdateCurrentStep(scrollToStep);
                    }
                }
            });
    }
}
import { Observable, fromEvent } from "rxjs";
import { filter } from "rxjs/operators";


class DomEventManager
{
    private static mInstance: DomEventManager;

    public OnLoad : Observable<any>;
    public SiteReloaded : Observable<any>;

    private constructor()
    {
        this.OnLoad = fromEvent(window, 'load');

        this.SiteReloaded = this.OnLoad
            .pipe(filter(() => this._CheckForSiteReload()));
    }

    private _CheckForSiteReload() : boolean
    {
      return performance !== undefined
        && performance.getEntriesByType('navigation') !== undefined
        && performance.getEntriesByType('navigation').length > 0
        && performance.getEntriesByType('navigation')[0].entryType != 'navigate';
    }

    public static GetInstance() : DomEventManager
    {
        if(DomEventManager.mInstance == null)
        {
            DomEventManager.mInstance = new DomEventManager();
        }
        return DomEventManager.mInstance;
    }
}

export const DOMEventManager = DomEventManager.GetInstance();

import { ShellAnimator } from "@webfacture/simplicitas-shell";
import { DotNavComponent } from "./dot-nav.component";
import { DotNavItemDataContext } from "./items/DotNavItemDataContext";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

export class DotNavSelectionChangedAnimator extends ShellAnimator<DotNavComponent>
{
    InitializeAnimations(): void 
    {
        gsap.registerPlugin(MorphSVGPlugin);

        this.ViewModel
            .Selected
            .subscribe((selection) => this._ExecuteSelectionChangedAnimation(selection));
    }

    private _ExecuteSelectionChangedAnimation(item: DotNavItemDataContext)
    {
        this._ResetCurrentSelected();

        const path = document.getElementById('nav-icon-' + item.Id.toString());

        gsap.to(path, {
            fill: "#9c7d4a",
        });
    }

    private _ResetCurrentSelected()
    {
        for (const item of this.ViewModel.Items) 
        {
            const path = document.getElementById('nav-icon-' + item.Id.toString());

            gsap.to(path, {
                fill: "#6D757E",
            });
        }
    }
}
<div #imagePickerContainer class="img-picker-container">

  <ng-template #defaultImagePickerContent>

    <img class="preview-img" src="{{ SelectedItem?.Source }}" alt="">

  </ng-template>

  <div class="desktop">

    <div class="top-part">

      <ng-container class="template-container"
        *ngTemplateOutlet="DataItemTemplate ? DataItemTemplate : defaultImagePickerContent">
      </ng-container>

    </div>

    <div class="bottom-part">
      <img *ngFor="let item of AvailableItems" src="{{ item.MinSource }}" (click)="item.Click.Execute();">
    </div>

  </div>

  <div id="mobile-container" class="mobile">

    <img class="prev-item-preview" src="{{ PrevItem?.Source}}" (click)="PrevItem.Click.Execute();">

    <ng-container class="template-container"
      *ngTemplateOutlet="DataItemTemplate ? DataItemTemplate : defaultImagePickerContent">
    </ng-container>

    <img class="next-item-preview" src="{{ NextItem?.Source}}" (click)="NextItem.Click.Execute();">

  </div>

</div>

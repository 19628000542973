<div id="references-section" class="references-section">

  <!-- <div class="img-overlay-wrap">

    <img src="assets/Startseite/DQDL_SS_24_21.jpg">
    <svg xmlns="http://www.w3.org/2000/svg" width="10%" height="20%" viewBox="0 0 51.48 51.48">
      <defs>
        <style>
          .cls-1 {
            fill: #fff;
          }

        </style>
      </defs>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path class="cls-1"
            d="M25.75,51.48A25.74,25.74,0,1,1,51.48,25.73v0A25.77,25.77,0,0,1,25.75,51.48ZM25.75,5A20.74,20.74,0,1,0,46.48,25.76h0A20.76,20.76,0,0,0,25.75,5Z" />
          <path class="cls-1"
            d="M40.75,23.62l-21-12.14a2.45,2.45,0,0,0-3.35.89A2.41,2.41,0,0,0,16,13.6V37.89A2.47,2.47,0,0,0,19.7,40l21-12.15a2.46,2.46,0,0,0,.93-3.35A2.53,2.53,0,0,0,40.75,23.62Z" />
        </g>
      </g>
    </svg>
    <div class="bottom-left">
      <h1>Christliche Ressourcen</h1>
      <h2>Himmelwärts</h2>
      <p>Neue Serien mit Pastor Mag. Kurt Piesslinger,</p>
      <p>bereichert mit Gemälden vom österreichischen Künstler Maximilian Jantscher</p>
    </div>

    <div class="bottom-right">
      <p>www.christlicheressourcen.com</p>
    </div>


    <a (click)="OpenChristReferences.Execute()">
      <span class="link"></span>
    </a>


  </div>

  <br>
  <br> -->

  <div class="img2-overlay-wrap">

    <img id="reference-img-2" src="assets/Startseite/DQDL_SS_26_21.jpg">
    <svg xmlns="http://www.w3.org/2000/svg" width="10%" height="20%" viewBox="0 0 51.48 51.48">
      <defs>
        <style>
          .cls-1 {
            fill: #fff;
          }

        </style>
      </defs>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path class="cls-1"
            d="M25.75,51.48A25.74,25.74,0,1,1,51.48,25.73v0A25.77,25.77,0,0,1,25.75,51.48ZM25.75,5A20.74,20.74,0,1,0,46.48,25.76h0A20.76,20.76,0,0,0,25.75,5Z" />
          <path class="cls-1"
            d="M40.75,23.62l-21-12.14a2.45,2.45,0,0,0-3.35.89A2.41,2.41,0,0,0,16,13.6V37.89A2.47,2.47,0,0,0,19.7,40l21-12.15a2.46,2.46,0,0,0,.93-3.35A2.53,2.53,0,0,0,40.75,23.62Z" />
        </g>
      </g>
    </svg>

    <div class="second-bottom-left">
      <h1> <i>Holy Painting</i> </h1>
      <h2>Atelier-Klasse Maestro</h2>
    </div>

    <a (click)="OpenYoutubeLink.Execute();">
      <span class="link"></span>
    </a>

  </div>
  <br>

</div>

import { Animator, ShellAngularOverlayComponent } from '@webfacture/simplicitas-shell';
import { Component } from '@angular/core';
import { FadeAnimator } from '../util/animator/FadeAnimator';
import { None } from '@webfacture/simplicitas-shared';
import { Observable, fromEvent } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { IFadeableComponent } from '../util/animator/IFadeableComponent';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
@Animator(new FadeAnimator<DatenschutzComponent>(0.4,0.6))
export class DatenschutzComponent extends ShellAngularOverlayComponent<DatenschutzComponent> implements IFadeableComponent
{
  public StartHiding: Observable<None>;
  public FadeTarget: HTMLElement;

  public async Initialize() : Promise<void>
  {

    this.FadeTarget = document.getElementById('datenschutz-container');
    const closeButton = document.getElementById('datenschutzCloseButton');

    this.StartHiding = fromEvent(closeButton, 'click')
      .pipe(map(_ => new None()));

    this.Disposables
      .Add(
        this.StartHiding
          .pipe(delay(700))
          .subscribe(async () => await this.OverlayContext.Hide()));
  }
}

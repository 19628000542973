
import { IEquatable } from '../../../../util/equality/IEquatable';
import { IImagePickerItemDataContext } from './../../../../util/img-picker/model/IImagePickerItemDataContext';

export class ArtReviewInfo implements IEquatable<ArtReviewInfo>, IImagePickerItemDataContext<ArtReviewInfo>
{
    ImageSource: string;
    PaintingName: string;
    PaintingDescription: string;
    MinImageSource: string | undefined;

    constructor(imgSource: string, paintingName: string, paintingDescription: string, minImageSource?: string)
    {
        this.ImageSource = imgSource;
        this.PaintingName = paintingName;
        this.PaintingDescription = paintingDescription;
        this.MinImageSource = minImageSource;
    }

    Equals(other: any): boolean 
    {
        if (other instanceof ArtReviewInfo)
        {
            return this.ImageSource === other.ImageSource
                && this.PaintingDescription === other.PaintingDescription
                && this.PaintingName === other.PaintingName;
        }
        return false;
    }
}